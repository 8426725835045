import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import UserStore from '../store/UserStore'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home2',
    meta: { authRequired: false, allowedRoles: [] },
    beforeEnter() {location.href = 'https://veecli.com/'}
  },  
  { 
    path: '/about',
    name: 'About',
    meta: { authRequired: false, allowedRoles: [] },
    beforeEnter() {location.href = 'https://veecli.com/about/'}
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { authRequired: false, allowedRoles: [] },
    beforeEnter() {location.href = 'https://veecli.com/contact/'}
  },  
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { authRequired: false, allowedRoles: [] },
    beforeEnter() {location.href = 'https://veecli.com/privacy/'}
  },
  {
    path: '/features',
    name: 'Features',
    meta: { authRequired: false, allowedRoles: [] },
    beforeEnter() {location.href = 'https://veecli.com/features/'}
  },      
  {
    path: '/signup',
    name: 'Signup',
    meta: { authRequired: false, allowedRoles: [] },
    component: () => import('../views/auth/Signup.vue')
  },  

  {
    path: '/register-user',
    name: 'RegisterUser',
    meta: { authRequired: false, allowedRoles: [] },
    component: () => import('../views/auth/RegisterUser.vue')
  }, 
  {
    path: '/login',
    name: 'Login',
    meta: { authRequired: false, allowedRoles: [] },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/calculators',
    name: 'Calculators',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/Calculators.vue')
  },   
  {
    path: '/user/edit',
    name: 'UserEdit',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/auth/UserProfile.vue')
  },  
  {
    path: '/user/storesmenu',
    name: 'StoresMenu',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/storesmenu/StoresMenu.vue')
  },
  {
    path: '/user/mystoressummary',
    name: 'StoresSummary',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/storesmenu/MyStoresSummary.vue')
  }, 
  {
    path: '/company/edit',
    name: 'CompanyEdit',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/companies/Edit.vue')

  },

  {
    path: '/store/employees',
    name: 'StoreEmployees',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/employees/Employees.vue')

  },  

  {
    path: '/store/delivery',
    name: 'StoreDeliverReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER', 'STAFF'] },
    component: () => import('../views/pages/reports/Delivery.vue')

  },  
  {
    path: '/store/deposit',
    name: 'BankDeposit',
    meta: { authRequired: false, allowedRoles: ['ADMIN', 'OWNER'] },
    component: () => import('../views/pages/reports/BankDeposits.vue')
  },
  {
    path: '/store/alarm',
    name: 'StoreAlarmsReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER', 'STAFF'] },
    component: () => import('../views/pages/reports/Alarm.vue')

  },  
  {
    path: '/store/payroll',
    name: 'PayrollReport',
    meta: { authRequired: false, allowedRoles: ['ADMIN', 'OWNER'] },
    component: () => import('../views/pages/reports/Payroll.vue')
  },
  {
    path: '/store/dfir',
    name: 'StoreDailyFuelInventoryReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/DailyFuelInventory.vue')

  },  
  {
    path: '/store/fuelInventory',
    name: 'StoreFuelInventoryReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/FuelCurrentInventory.vue')

  }, 
  {
    path: '/store/leakreport',
    name: 'StoreLeakDetectionReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/LeakDetection.vue')
  },  

  {
    path: '/store/create',
    name: 'StoreCreate',
    meta: { authRequired: true, allowedRoles: [] },
    component: () => import('../views/pages/stores/store/Create.vue')

  },

  {
    path: '/store/edit',
    name: 'StoreEdit',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/store/Edit.vue')

  },
  {
    path: '/store/settings',
    name: 'StoreSettings',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/settings/StoreSettings.vue')
  },
  {
    path: '/store/lottery/games',
    name: 'LotteryGames',
    meta: { authRequired: true, allowedRoles: ['ADMIN'] },
    component: () => import('../views/pages/stores/lottery/games/LotteryGames.vue')
  },
  {
    path: '/store/lottery/sales',
    name: 'LotterySales',
    meta: { authRequired: true, allowedRoles: ['ADMIN'] },
    component: () => import('../views/pages/stores/lottery/sales/LotterySales.vue')
  },    
  {
    path: '/store/cashregister',
    name: 'CashRegister',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/store/CashRegister.vue')

  },

  {
    path: '/store/shift',
    name: 'StoreShift',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER', 'STAFF'] },
    component: () => import('../views/pages/stores/shifts/Shifts')

  },
  
  {
    path: '/store/ccrecon',
    name: 'CreditRecon',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/creditcard/CreditCardReconcilation')

  },
  {
    path: '/store/ccdeposit',
    name: 'CreditDeposit',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/creditcard/CreditCardNetDeposit')

  },
  {
    path: '/store/fuelpayments',
    name: 'FuelPayments',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/expenses/Payments/FuelPayments.vue')

  },

  {
    path: '/store/products/departments/mapping',
    name: 'DepartmentMapping',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/products/departments/Mapping.vue')

  },

  {
    path: '/store/sales',
    name: 'StoreSales',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/DailySailesSummary.vue')

  },

  {
    path: '/store/department',
    name: 'DeptSales',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/DepartmentSales.vue')

  },

  {
    path: '/store/lottery',
    name: 'StoreLottery',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/lottery/Lottery.vue')

  },
  // {
  //   path: '/store/lotteryspotcheck',
  //   name: 'StoreLotterySpot',
  //   meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
  //   component: () => import('../views/pages/stores/spotcheck/LotterySpotCheck.vue')

  // }, 
  {
    path: '/store/lottery/audit',
    name: 'StoreLotteryAudit',
    meta: { authRequired: true, allowedRoles: ['ADMIN','MANAGER','OWNER'] },
    component: () => import('../views/pages/stores/lottery/audit/LotteryAudit.vue')

  },   
  {
    path: '/store/expenses',
    name: 'StoreExpenses',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/expenses/Expenses.vue')

  },
  {
    path: '/store/income',
    name: 'StoreIncome',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/income/Income.vue')

  },
  {
    path: '/store/expenseTypes',
    name: 'StoreExpenseTypes',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/expenses/types/ExpenseTypes.vue')

  },
  {
    path: '/store/vendors',
    name: 'StoreVendors',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/vendors/Vendors.vue')

  },

  {
    path: '/store/dashboard',
    name: 'StoreDashboard',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/dashboard/MainDashboard.vue')

  },

  {
    path: '/store/bank/verify',
    name: 'StoreVerifyBankAccount',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/store/VerifyBankAccount.vue')

  },

  {
    path: '/store/users',
    name: 'StoreUsers',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/users/Users.vue')

  },
  {
    path: '/store/banks',
    name: 'StoreBankRegister',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/bank/PlaidLink.vue')

  },
  {
    path: '/store/products/list',
    name: 'ProducList',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/products/ProductList.vue')

  }, 
  {
    path: '/store/products/productScan',
    name: 'productScan',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/products/ProductScan.vue')

  }, 
  {
    path: '/store/products/updates',
    name: 'ProducUpdates',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/products/ProductUpdates.vue')

  }, 
  {
    path: '/store/banks/accounts',
    name: 'StoreBankAccounts',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/bank/Accounts.vue')

  },
  {
    path: '/store/reports/commission',
    name: 'CommissionReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/Commission.vue')

  },  
  {
    path: '/store/reports/accountantSummary',
    name: 'AccountantSummary',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER', 'ACCOUNTANT'] },
    component: () => import('../views/pages/reports/AccountantSummary.vue')

  },  
  {
    path: '/store/reports/shiftcalendar',
    name: 'ShiftCalendar',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/ShiftCalendar.vue')

  },
  {
    path: '/store/reports/shiftreport',
    name: 'ShiftReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/ShiftReport.vue')

  },  
  {
    path: '/store/reports/revenuesummary',
    name: 'RevenueSummaryReport',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/reports/RevenueSummary.vue')

  },
  {
    path: '/subscription-required',
    name: 'StoreSubscriptionRequired',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/SubscriptionRequired.vue')

  },
  {
    path: '/partner/tankinventory',
    name: 'PartnerTankInventoryPage',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/pages/stores/tanks/tankInventory.vue')
  },
  {
    path: '/admin/storesstatus',
    name: 'StoresStatus',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'MANAGER', 'OWNER'] },
    component: () => import('../views/monitor/IotStatus.vue')
  },
  {
    path: '/store/monthend',
    name: 'StoreMonthEnd',
    meta: { authRequired: true, allowedRoles: ['OWNER', 'MANAGER','ADMIN'] },
    component: () => import('../views/pages/stores/monthend/MonthEndSeparated.vue')
  },
  {
    path: '/support/clientconfig',
    name: 'clientConfig',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'OWNER'] },
    component: () => import('../views/support/ClientConfig.vue'),
  },
  {
    path: '/support/remote',
    name: 'RemoteSession',
    meta: { authRequired: false},
    component: () => import('../views/support/RemoteSession.vue'),
  },     
  {
    path: '/platform/tanks',
    name: 'PlatformTanks',
    meta: { authRequired: true, allowedRoles: ['ADMIN', 'PLATFORM_FUEL_DATA'] },
    component: () => import('../views/pages/platform/Tanks.vue')
  },  
  // Invalid Path or not authorized
  {
    path: '/*',
    name: 'NotAuthorized',
    meta: { authRequired: false, allowedRoles: ['OWNER', 'MANAGER','ADMIN','STAFF', 'ACCOUNTANT'] },
    component: () => import('../views/auth/NotAuthorized.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  UserStore.commit('setProgressPercent',0)

  if (! to.meta.authRequired) {
    next()
  } else {
    var userAuthorized = to.meta.allowedRoles.filter(item => (item === UserStore.getters.getActiveStore.role))
    if (userAuthorized.length > 0) {
      next()
    } else {
      if (to.fullPath == '/store/create' || to.fullPath == '/user/edit') {
        if (UserStore.getters.isAuth) {
          next()
        } else {
          // next(false)
          // router.push({ path: '/notauthorized' })
          next({ name: 'NotAuthorized' })
        }
      } else {
      //   next(false)
      //   router.push({ path: '/notauthorized' })
        next({ name: 'NotAuthorized' })
      }
    }
  }
})

export default router
