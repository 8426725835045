<template>
    <div class="is-primary" id="primarydiv">
      <title-bar :user="user" :sideBarOn="sideBarOn" v-on:toggle-side-bar="toggleSideBar"></title-bar>
      <div class='columns is-mobile'  v-if="user.name">
        <!-- <div class="column is-2"  v-if="user.name && sideBarOn">
            <side-bar></side-bar>
        </div> -->
        <side-bar :user="user" :sideBarOn="sideBarOn" v-on:toggle-side-bar="toggleSideBar"></side-bar>
        <div class="column">
          <div class='right-padding main-content'>
            <article class="box">
            <b-modal :active.sync="modelActive" scroll="keep" @click="modelActive=false">>
              <b-progress :size="progressSize" :type="progressType" v-if="progressPercent > 0" :value="progressPercent" :max="progressMax" show-progress animated></b-progress>
            </b-modal>

            <b-message class="mt-2"
              v-if="notice.message != '' && notice.active == 'true'"
              title="Notification" 
              type="is-warning" 
              aria-close-label="Close message">
              {{ notice.message }}
            </b-message>

            <router-view/>
            </article>
          </div>
        </div>
      </div>
      <div class='columns is-mobile' v-else>
        <div class="column">
          <router-view/>
        </div>
      </div>

      <footer-bar></footer-bar>
      <!-- <idle-logout></idle-logout> -->
    </div>   
</template>

<script>
import TitleBar from './components/app/TitleBar.vue'
import FooterBar from './components/app/FooterBar.vue'
import SideBar from './components/app/SideBar.vue'
// import IdleLogout from './components/app/IdleLogout.vue'
import UserStore from "./store/UserStore"

export default {

  metaInfo: {
    title: "Gas Station Manager - VEECLi.com",
    titleTemplate: '%s ← VEECLi.com',
    meta: [
      {"http-equiv": 'Content-Type', "content": 'text/html; charset=utf-8'},
      {"name": 'description', "content": 'Vue.js apps and sites for search engines.'}
    ]
  },
  
  components: {
    'title-bar': TitleBar,
    'footer-bar': FooterBar,
    'side-bar': SideBar,
    // 'idle-logout': IdleLogout
  },

  data() {
    return {
      apptitle: process.env.VUE_APP_URL,
      user: {},
      isCardModalActive: false,
      sideBarOn: true,
      notice: {
        "message": process.env.VUE_APP_MAINTENANCE_MESSAGE,
        "active": process.env.VUE_APP_MAINTENANCE_ACTIVE
      }
    }
  },

  methods: {
    toggleSideBar() {
        this.sideBarOn = ! this.sideBarOn
    },

    onResize() {
      if (window.innerWidth <=1000) {
        this.sideBarOn = false
      } else {
        this.sideBarOn = true
      }
    }        
  },

  watch:{
    $route (){
 
        if (this.getWindowWidth() < 1150) this.sideBarOn = false
        window.scrollTo(0, 0);
    }
  },


  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
    
    progressPercent() {
      return UserStore.getters.getProgressPercent
    },

    progressMax() {
      return UserStore.getters.getProgressMax
    },
  
    progressSize() {
      return UserStore.getters.getProgressSize
    },

    progressType() {
      return UserStore.getters.getProgressType
    },

    modelActive : {
      get () {
        if (UserStore.getters.getProgressPercent > 0) {
          return true
        } else {
          return false
        }
      },
      set () {
        UserStore.commit('setProgressPercent', 0)
      }
    },

  },

  async mounted(){
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.onResize()

    UserStore.commit('setProgressPercent', 0)
    UserStore.commit('setProgressType', 'is-success')

    if (sessionStorage.getItem('auth')) {

        this.user = JSON.parse(sessionStorage.getItem('auth')).user

    } else {
        this.user = []
    }

    // console.log("Window width",this.getWindowWidth())

    if (this.getWindowWidth() < 1150) this.sideBarOn = false

  }


}
</script>

<style>

:root {
   --primary: #990009;
}

</style>

<style scoped>
.b-model {
  z-index: 6000;
}

.right-padding {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.main-content {
  margin-top: 60px;
  margin-right: auto;
}
.sidebarmenu {
  z-index: 2000;
}

b-input[type=text] {
  background-color: rgb(237, 246, 235);
}

#for-example {
  background-color: blue;
} 
</style>