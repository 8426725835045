<template>
    <div>
        <b-message class="mt-6"
            v-if="notice.message != '' && notice.active == 'true'"
            title="Notification" 
            type="is-warning" 
            aria-close-label="Close message">
            {{ notice.message }}
        </b-message>
        <login-form></login-form>
    </div>
</template>

<script>

import LoginForm from '../../components/app/LoginForm'

export default {
    components: {
        'login-form': LoginForm
    },

    data() {
        return {
            notice: {
                "message": process.env.VUE_APP_MAINTENANCE_MESSAGE,
                "active": process.env.VUE_APP_MAINTENANCE_ACTIVE
            }
        }
    },
    
}
</script>