<template>
<div class="panel">

    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">

            <div class="column is-size-6 has-text-centered"> {{ tabTitle }} </div>
            <div class="level-right has-text-centered">
                <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                    <i class="fas fa-print"></i>
                </b-button>
                <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
            </div>                                                               
        </div>           
    </div>
    <br>
    <div  v-if="getActiveStore.role != 'STAFF'">
            <b-tabs v-model="activeTab" @input="updateTabData">
                <b-tab-item>
                    <template #header>
                        <b-icon icon="information-outline"></b-icon>
                        <span class="has-text-weight-bold">Expenses</span>
                    </template>
                    <div class="panel has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-3">
                                    <b-field>
                                        <date-range-selector
                                            :dateRange="salesDateRange"
                                            @set-date-range="setDateRange"
                                            >
                                        </date-range-selector>
                                    </b-field>
                                </div>
                                
                                <div class="column is-1">
                                    
                                </div>
                                <div class="column is-2">
                                    
                                    <b-field  label="Date Range Filter On">
                                        <b-select v-model="dateFilter" @input="onSelectChange($event,'dateFilter')">
                                            <option value="Cleared Date">Cleared Date</option>
                                            <option value="Expected Date">Expected Date</option>
                                            <option value="Expense Date" selected>Expense Date</option>
                                        </b-select>
                                    </b-field>
                           
                                    <b-field>
                                        <b-switch 
                                            true-value="1"
                                            false-value="0"
                                            size="is-small"
                                            v-model="search.onlyCash"
                                            @input="onSelectChange($event,'onlyCash')"
                                            type="is-success">
                                                Only Cash
                                        </b-switch>
                                        <b-switch 
                                            true-value="1"
                                            false-value="0"
                                            size="is-small"
                                            v-model="search.excludeCash"
                                            @input="onSelectChange($event,'excludeCash')"
                                            type="is-success">
                                                Exclude Cash
                                        </b-switch>
                                    </b-field>
                                </div>
                                <div class="column is-2">
                                    <b-field label="Vendor" label-for="vendorId"  v-if="search.expenseTypeId != 1">
                                        <b-select v-model="search.vendorId" @input="onSelectChange($event,'vendorId')">
                                            <option value="0">All Vendors</option>
                                            <option v-for="item in vendors" :key="item.id" :value="item.id">{{ item.name }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-2">                                    
                                    <b-field label="Type of Expense" label-for="expenseTypeId">
                                        <b-select v-model="search.expenseTypeId" @input="onSelectChange($event,'expenseTypeId')">
                                            <option value="0">All Expense Types</option>
                                            <option v-for="item in expenseTypes" :key="item.id" :value="item.id">{{ toTitleCase(item.name) }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-2" v-if="search.expenseTypeId == 2">                                    
                                    <b-field label="Inventory Department" label-for="deptMapping">
                                        <b-select v-model="search.deptMapping" @input="onSelectChange($event,'deptMapping')">
                                            <option value="">All Departments</option>
                                            <option v-for="item in storeMappings" :key="item.value" :value="item.value">{{ item.name }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-2" v-if="search.expenseTypeId == 1">
                                    
                                    <b-field label="Employee" label-for="employeeId">
                                        <b-select v-model="search.employeeId" @input="onSelectChange($event,'employeeId')">
                                            <option value="0">All Employees</option>
                                            <option v-for="item in employees" :key="item.id" :value="item.id">{{ item.firstName + " " + item.lastName }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div> 

                        <expense-table
                            :expenses="records"
                            :isPaginated="isPaginated"
                            :page="page"
                            :perPage="perPage"
                            :search="search"
                            :totalRecords="totalRecords"
                            :totalExpenses="totalExpenses"
                            :totalUnpaidExpenses="totalUnpaidExpenses"
                            :totalPaidExpenses="totalPaidExpenses"
                            :backendFiltering="backendFiltering"
                            :showExpenseType="showExpenseType"
                            :isLoading="isLoading"
                            :isFullPage="isFullPage"
                            @column-sort="onColumnSort"
                            @page-changed="onPageChange"
                            @fetch-records="updateTabData"
                            @filters-changed="filtersChanged"
                            >
                        </expense-table>

                    </div>
                </b-tab-item>

                <b-tab-item>
                    <template #header>
                        <b-icon icon="information-outline"></b-icon>
                        <span class="has-text-weight-bold is-hidden-touch">Not Cleared</span>
                        <span class="has-text-weight-bold is-hidden-desktop">Open</span>
                    </template>                    
                        <expense-table
                            :expenses="records"
                            :isPaginated="isPaginated"
                            :storeId="storeId"
                            :perPage="perPage"
                            :page="page"
                            :totalRecords="totalRecords"
                            :totalExpenses="totalExpenses"
                            :totalUnpaidExpenses="totalUnpaidExpenses"
                            :totalPaidExpenses="totalPaidExpenses"
                            :backendFiltering="backendFiltering"
                            :showExpenseType="showExpenseType"
                            :isLoading="isLoading"
                            :isFullPage="isFullPage"
                            @column-sort="onColumnSort"
                            @page-changed="onPageChange"
                            @fetch-records="updateTabData"
                            @filters-changed="filtersChanged"
                            >
                        </expense-table>
                    <!-- </div> -->

                </b-tab-item>  

                <b-tab-item>
                    <template #header>
                        <b-icon icon="information-outline"></b-icon>
                        <span class="has-text-weight-bold is-hidden-touch">Due in 7 Days</span>
                        <span class="has-text-weight-bold is-hidden-desktop">Due</span>
                    </template> 
                        <expense-table
                            :expenses="records"
                            :isPaginated="isPaginated"
                            :storeId="storeId"
                            :perPage="perPage"
                            :page="page"
                            :totalRecords="totalRecords"
                            :totalExpenses="totalExpenses"
                            :totalUnpaidExpenses="totalUnpaidExpenses"
                            :totalPaidExpenses="totalPaidExpenses"
                            :backendFiltering="backendFiltering"
                            :showExpenseType="showExpenseType"
                            :isLoading="isLoading"
                            :isFullPage="isFullPage"
                            @column-sort="onColumnSort"
                            @page-changed="onPageChange"
                            @fetch-records="updateTabData"
                            @filters-changed="filtersChanged"
                            >
                        </expense-table>
                    <!-- </div> -->

                </b-tab-item>                                
            </b-tabs>
            <expense-PDF-report
                ref="pdfReport"
                :isShowPDFDialog="isShowPDFDialog"
                :showExpenseType="showExpenseType"
                :dateFilter="dateFilter"
                :search="search"
                :filteredRecords="records"
                :dateRange="salesDateRange"
                @close-pdf-dialog="togglePDFDialog"
            >

            </expense-PDF-report>
    </div>
</div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DateRangeSelector from "../../../../components/app/DateRangeSelector"
import axios from 'axios'

import ExpenseTable from './ExpenseTable.vue'
import ExpensePDFReport from './ExpensePDFReport.vue'

export default {

    components: {
        DateRangeSelector,
        ExpenseTable,
        ExpensePDFReport
    },

    metaInfo: {
        title: 'Expenses'
    },

    data() {
        return {
            user: [],
            records: [],
            expense: {},
            activeTab: 0,
            tabTitle: "EXPENESES",
            expenseDueThisWeek: [],
            expenseNotCleared: [],
            expenseTemp: {},
            isPaginated: true,
            perPage: 20,
            setPerPage: 20,
            totalRecords: 0,
            page: 1,
            backendFiltering: true,
            sortField: 'sale.closingDate',
            sortOrder: 'desc',
            dateSortOrder: "desc",
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            salesDateRange: [new Date(), new Date()],
            expenseTypes: [],
            showExpenseType: "",
            totalExpenses: 0.00,
            totalPaidExpenses: 0.00,
            totalUnpaidExpenses: 0.00,
            dateFilter: 'Expense Date',
            isShowPDFDialog: false,
            search: {
                "vendorId": 0,
                "vendorName": "",
                "expenseTypeId": 0,
                "expenseTypeName": "",
                "deptMapping": "",
                "employeeId": 0,
                "employeeName": "",
            },
            vendorId: 0,
            searchVendorName: "",
            expenseTypeId: 0,
            searchExpenseType: "",
            searchDesc: "",
            searchDeptMapping: "",
            searchEmployeeId: 0,
            // deptMappings: UserStore.getters.getDeptMappings,
            storeMappings: UserStore.getters.getStoreMappings,
            vendors: [],
            employees: [],
        }
    },

    methods: {

        async fetchRecords() {
            
            if (this.user.activeStore) {
                
                this.records = []
                this.isLoading = true

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                var day365Back =  new Date(Date.now() - 365 * 24*60*60*1000);
                var day7Forward = new Date(Date.now() + 7 * 24*60*60*1000);

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/expenses/search?"

                var showPage = this.page - 1
                if (this.isShowPDFDialog) {
                    url = url + "page=0&size=1M"
                } else {
                    this.perPage = this.setPerPage
                    url = url + "page="+showPage+"&size=" + this.perPage
                }   
                

                if (this.sortField == "") {
                    this.sortField = "sale.closingDate"
                }
                if (this.sortOrder == "") {
                    this.sortOrder = "desc"
                }                

                // url = url + "&sort="+(this.sortField ? this.sortField : "sale.closingDate")+","+(this.sortOrder ? this.sortOrder : "desc")
                url = url + "&sort="+this.sortField+","+this.sortOrder

                if (this.tabTitle == "PAYMENTS NOT CLEARED") {
                    url = url+ "&paymentStatus=Open,NSF"
                } else {
                    if (this.tabTitle == "PAYMENTS DUE IN 7 DAYS") {
                        startDate = day365Back.toISOString().split('T')[0]
                        endDate = day7Forward.toISOString().split('T')[0]

                        url = url+ "&paymentStatus=Open,NSF"
                        url = url + "&expectClearDateFrom="+startDate
                        url = url + "&expectClearDateTo="+endDate
                    } else {
                        if (this.dateFilter === 'Expense Date') {
                            url = url + "&expenseDateFrom="+startDate+"&expenseDateTo="+endDate
                        }
                        if (this.dateFilter === 'Expected Date') {
                            url = url + "&expectClearDateFrom="+startDate+"&expectClearDateTo="+endDate
                        }
                        if (this.dateFilter === 'Due Date') {
                            url = url + "&dueDateFrom="+startDate+"&dueDateTo="+endDate
                        }
                        if (this.dateFilter === 'Cleared Date') {
                            url = url + "&paymentClearDateFrom="+startDate+"&paymentClearDateTo="+endDate
                        }

                        if (this.search.excludeCash == "1") {
                            url = url+ "&paymentStatus=Open,NSF,Cleared"
                        }

                        if (this.search.onlyCash == "1") {
                            url = url+ "&paymentMethod=Cash"
                        }

                        if (this.search.vendorId > 0) {
                            url = url + "&expenseVendorId=" + this.search.vendorId
                        }
                        if (this.search.paymentMethod != "All") {
                            url = url + "&payment.method=" + this.search.paymentMethod
                        }

                        if (this.search.expenseTypeId > 0) {
                            url = url + "&expenseTypeId=" + this.search.expenseTypeId
                            if (this.search.expenseTypeId == 2 && this.search.deptMapping != "") {
                                url = url + "&departmentMapping=" + this.search.deptMapping
                            }
                            if (this.search.expenseTypeId == 1 && this.search.employeeId != 0) {
                                url = url + "&employeeId=" + this.search.employeeId
                            }
                        }
                    }
                }
                
                if (this.searchVendorName) {
                    url = url + "&vendorName=" + this.searchVendorName
                }
                if (this.searchExpenseType) {
                    url = url + "&expenseTypeName=" + this.searchExpenseType
                }
                if (this.searchDesc) {
                    url = url + "&description=" + this.searchDesc
                }
                                                                                       
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data.expenses.content
                        this.totalPages = response.data.data.expenses.totalPages
                        this.page = response.data.data.expenses.number
                        this.perPage = response.data.data.expenses.size
                        this.totalRecords = response.data.data.expenses.totalElements
                        this.totalExpenses = response.data.data.totalExpense
                        this.totalUnpaidExpenses = response.data.data.totalUnpaidExpenes
                        this.totalPaidExpenses = this.totalExpenses - this.totalUnpaidExpenses
                        if (this.records.length == 0) {
                            this.isLoading = false
                        } else {
                            if (this.isShowPDFDialog) {
                                this.$refs.pdfReport.loadData()
                            }
                        }
                        // this.showExpenseType = ""
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchExpenseTypes() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/expense_type'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        this.expenseTypes = response.data.data.sort((a, b) => (a.name > b.name) ? 1 : -1)

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },        

        fetchVendors() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/vendors'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.vendors = this.sortArrayByProp(response.data.data,"name")
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchEmployees() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/employees'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.employees = this.sortArrayByProp(response.data.data,"firstName")
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
        },

        async togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            await this.fetchRecords()
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },
   
        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        onPageChange(page) {
            this.page = page
            this.fetchRecords()
        },

        onColumnSort(field,order) {
            this.sortField = field
            this.sortOrder = order
            this.fetchRecords()
        },

        onSelectChange(selectedValue, selectField) {
            var selObj = {}
            switch (selectField) {
                case "vendorId":
                    selObj = this.vendors.find(o => o.id === selectedValue)
                    if (selObj) {
                        this.search.vendorName = selObj.name   
                    } else {
                        this.search.vendorName = ""
                    }
                    break

                case "expenseTypeId":
                    selObj = this.expenseTypes.find(o => o.id === selectedValue)
                    if (selObj) {
                        this.search.expenseTypeName = selObj.name
                    } else {
                        this.search.expenseTypeName = ""
                    }                    
                    if (this.search.expenseTypeId == 1) {
                        this.showExpenseType = selObj.name
                    } else {
                        if (this.search.expenseTypeId == 2) {
                            this.showExpenseType = selObj.name
                        } else {
                            this.search.employeeId = 0
                            this.search.employeeName = ""
                        }
                    }
                    // if (this.search.expenseTypeId != 2) {
                    //     this.search.deptMapping = ""
                    // }
                    break

                case "deptMapping":
                    // this.search.expenseTypeName = selectedValue
                    this.showExpenseType = selectedValue
                    break

                case "employeeId":
                    selObj = this.employees.find(o => o.id === selectedValue)
                    console.log("emp", selObj)
                    if (selObj) {
                        this.search.employeeName = selObj.firstName + " " + selObj.lastName   
                    } else {
                        this.search.employeeName = ""
                    }                    
                    break
                    
                case "excludeCash":
                    this.search.onlyCash = 0
                    break

                case "onlyCash":
                    this.search.excludeCash = 0
                    break
                
            }
            this.fetchRecords()           
        },

        filtersChanged(event) {
            this.searchVendorName = event.vendorName
            this.searchExpenseType = event.expenseTypeName
            this.searchDesc = event.description
            this.fetchRecords()
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        dueOnDate(dateString) {
            var dueOn = new Date(dateString)
            var today = new Date()
            if (dueOn < today) {
                return "Past Due " + (dueOn.getMonth()+1) + '/' + dueOn.getDate() + '/' + dueOn.getFullYear() 
            } else {
                return `'Due ' + (dueOn.getMonth()+1) + '/' + dueOn.getDate() + '/' + dueOn.getFullYear()`
            }
            
        },

        statusClass() {
            return "has-background-primary"
        },

        doAction(action, expense) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.toggleDialog()
                    this.expenseTemp = {
                        "date": new Date(),
                        "dueOn": new Date(),
                        "description": "",
                        "amount": "",
                        "expenseType": "",
                        "creditTerm": "",
                        "vendorId": "",
                        "userId": this.user.id,
                        "storeId": this.storeId,
                    }
                    break

                case 'edit':
                    this.toggleDialog()
                    this.expense = expense
                    this.expenseTemp = Object.assign({}, expense)
                    
                    break

                case 'pay':
                    this.$buefy.dialog.alert('Not Implemented yet!')
                    break
            }

        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },
        
        updateTabData() {
            this.isLoading = true
            this.searchVendorName = ""
            this.searchExpenseType = ""
            this.searchDesc = ""
            switch (this.activeTab) {
                case 0:
                    this.tabTitle = "EXPENESES"
                    this.fetchRecords()
                        .then(() => {
                            // this.isLoading = false
                        })
                    break
                    
                case 1:
                    this.tabTitle = "PAYMENTS NOT CLEARED"
                    // this.fetchPaymentsNotCleared()
                        this.fetchRecords()
                        .then(() => {
                            // this.isLoading = false
                        })
                    break

                case 2:
                    this.tabTitle = "PAYMENTS DUE IN 7 DAYS"
                    this.fetchRecords()
                        .then(() => {
                            // this.isLoading = false
                        })
                    break
            }
            // 
        },   
    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    watch: {
        salesDateRange: function() {
            
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.isLoading = true
                this.search.vendorId = 0
                this.search.paymentMethod = "All"
                this.search.expenseTypeId = 0
                this.search.deptMapping = ""
                this.search.employeeId = 0
                this.search.excludeCash = 0
                this.search.onlyCash = 0
                this.dateFilter= "Expense Date" 
                this.isShowPDFDialog = false
                this.fetchRecords()
                    // .then(() => {
                    //     this.isLoading = false
                    // })
            }
        },

        dateFilter: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.records = []
                this.search.vendorId = 0
                this.search.expenseTypeId = 0
                this.search.deptMapping = ""
                this.search.employeeId = 0
                this.search.excludeCash = 0
                this.search.onlyCash = 0
                this.isLoading = true
                this.fetchRecords()
            }
        },

        // showExpenseType() {
        //     this.isLoading = true
        //     this.fetchRecords()
        // },

    },    

    mounted() {
        
        this.user = this.getUser()
        var today = new Date()

        this.fetchVendors()
        this.fetchExpenseTypes()
        this.fetchEmployees()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]
        
    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}
</style>