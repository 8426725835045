<template>
    <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">FUEL INVENTORY RECONCILIATION</div>
                <div class="column has-text-right is-1">
                    <b-button title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                </div>                

            </div>
        </div>
        <div class="columns">
            <div class="column is-4 has-text-left">
                <date-range-selector
                    :dateRange="dateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
        </div> 
        <hr>

        <b-tabs size="is-small" class="block" position="is-left" type="is-boxed" v-if="groupedInventory.length > 0">
            <!-- <b-tab-item  :label="prod[index].product" icon="google-photos" v-bind:key="prod[index].product" v-for="(prod, index) in groupedInventory"> -->
                <b-tab-item :label="prod[0].product" v-bind:key="index"  icon="google-photos" v-for="(prod,index) in groupedInventory">
                <b-table 
                    :data='prod'
                    :paginated="isPaginated"
                    :per-page="perPage"
                    paginationPosition="both"
                    sortIcon="arrow-up"
                    sortIconSize="is-small"
                    default-sort=date
                    defaultSortDirection='asc'
                    >

                    <template>

                        <b-table-column class="is-2 has-text-right" field="Edit" label="Edit" v-slot="props">
                            <b-button  class="is-primary is-small"  v-on:click="doAction(props.row)" outlined rounded>
                                <i class="far fa-edit"></i>
                            </b-button>
                        </b-table-column> 
                        
                        <b-table-column field="date" label="Date" v-slot="props" sortable sorticon>
                            {{ props.row.date | formatDate }}
                        </b-table-column>

                        <b-table-column numeric field="startingVolume" label="ATG Open" v-slot="props" sortable sorticon>
                            <span :class="{'has-text-warning-dark': props.row.previousDayManualEndingVolume}">
                                {{ formatNumberWithCommas(props.row.netOpenVol,decPoints) }}
                            </span>
                        </b-table-column>

                        <b-table-column numeric field="deliveredVolume" label="ATG Delivery" v-slot="props" sortable sorticon>
                            {{ formatNumberWithCommas(props.row.deliveredVolume,decPoints) }}
                        </b-table-column>

                        <b-table-column numeric field="soldVolume" label="POS Sales" v-slot="props" sortable softicon>
                            {{ formatNumberWithCommas(props.row.soldVolume,decPoints) }}
                        </b-table-column>

                        <b-table-column numeric field="netBalanceVol" label="ATG Balance" v-slot="props" sortable sorticon>
                            <span :class="{'has-text-danger': props.row.netBalanceVol < 0.00 }">
                                {{ formatNumberWithCommas(props.row.netBalanceVol,decPoints) }}
                            </span> 
                        </b-table-column>

                        <b-table-column numeric field="endingVolume" label="ATG Close" v-slot="props" sortable sorticon>
                            <span :class="{'has-text-warning-dark': props.row.manualEndingVolume}">
                                <span :class="{'has-text-danger': props.row.netCloseVol < 0.00 }">
                                    {{ formatNumberWithCommas(props.row.netCloseVol,decPoints) }}
                                </span> 
                            </span>
                        </b-table-column>

                        <b-table-column numeric field="atgVariance" label="ATG Variance" v-slot="props" sortable sorticon>
                            <span :class="{'has-text-danger': props.row.netVarianceVol < 0.00 }">
                                {{ formatNumberWithCommas(props.row.netVarianceVol,decPoints) }}
                            </span> 
                            
                        </b-table-column>                        

                        <b-table-column numeric v-if="showPOSData(index)" field="netVarianceVolPOS" label="POS Open" v-slot="props" sortable sorticon>
                            
                            <span :class="{'has-text-warning-dark': props.row.previousDayManualEndingVolumePOS}">
                                {{  formatNumberWithCommas(netOpenVolPOS(props.row),decPoints) }}
                            </span>
                        </b-table-column>

                        <b-table-column numeric field="netDeliveryVolPOS" 
                            v-if="showPOSData(index)" 
                            label="POS Delivery"  v-slot="props" sortable sorticon>
                            <span :class="{'has-text-warning-dark': props.row.manualDeliveryVolumePOS}">
                                {{ formatNumberWithCommas(props.row.netDeliveryVolPOS,decPoints) }}
                            </span>
                        </b-table-column>                        

                        <b-table-column numeric v-if="showPOSData(index)" field="netCloseVolPOS" label="POS Close"  v-slot="props" sortable sorticon>
                            <span :class="{'has-text-warning-dark': props.row.manualEndingVolumePOS}">
                                {{ formatNumberWithCommas(props.row.netCloseVolPOS,decPoints) }}
                            </span>
                        </b-table-column>

                        <b-table-column numeric v-if="showPOSData(index)" field="netVarianceVolPOS" label="POS Variance" v-slot="props" sortable sorticon>
                            <span :class="{'has-text-danger': props.row.netVarianceVolPOS < 0.00 }">
                                {{ formatNumberWithCommas(props.row.netVarianceVolPOS,decPoints) }}
                            </span>                            
                        </b-table-column>                                                

                    </template>


                    <template slot="footer">
                        <div class="columns" v-if="productTotals.length > 0">
                            <div class="column">
                                <div class="panel">
                                    <div class="panel-heading has-text-weight-bold has-text-centered">
                                        VEEDER ROOT (ATG)
                                    </div>
                                    <div class="panel-content">
                                        <div class="columns  has-text-centered">
                                            <div class="column">
                                                <p class="is-size-7">Open</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].firstDayOpenVol,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Delivery</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalNetDeliveryVol,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Sales</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalSoldVolume,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Balance</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalNetBalance ,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Close</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].lastDayCloseVol,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Variance</p>
                                                <p class="is-size-6">
                                                    <span :class="{'has-text-danger': productTotals[index].totalNetVariance < 0.00 }">
                                                        {{ formatNumberWithCommas(productTotals[index].totalNetVariance,decPoints) }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column" v-if="showPOSData(index)">
                                <div class="panel">
                                    <div class="panel-heading has-text-weight-bold has-text-centered">
                                        VERIFONE (POS)
                                    </div>
                                    <div class="panel-content">
                                        <div class="columns  has-text-centered">
                                            <div class="column">
                                                <p class="is-size-7">Open</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].firstDayOpenVolPOS,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Delivery</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalNetDeliveryVolPOS,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Sales</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalSoldVolume,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Balance</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].totalNetBalancePOS ,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Close</p>
                                                <p class="is-size-6"> {{ formatNumberWithCommas(productTotals[index].lastDayCloseVolPOS,decPoints) }}</p>
                                            </div>
                                            <div class="column">
                                                <p class="is-size-7">Variance</p>
                                                <span :class="{'has-text-danger': productTotals[index].totalNetVariancePOS < 0.00 }">
                                                    {{ formatNumberWithCommas(productTotals[index].totalNetVariancePOS,decPoints) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template> 
                  
                    <template slot="empty">
                        <data-loading 
                            :isLoading="isLoading" 
                            :isFullPage="isFullPage"> 
                        </data-loading>
                    </template>

                </b-table>
                
            </b-tab-item>
        </b-tabs>
        <daily-fuel-inventory-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :dateRange="dateRange"
            :groupedInventory="groupedInventory"
            :productTotals="productTotals"
            @close-pdf-dialog="togglePDFDialog"
        >
        </daily-fuel-inventory-pdf>
        <daily-fuel-inv-edit-form
            ref="editForm"
            :isShowEditDialog="isShowEditDialog"
            :fuelInvEdit="fuelInvEdit"
            @hide-dialog=hideDialog
            @save-record="saveEditRecord">

        </daily-fuel-inv-edit-form> 
    </div>
</template>

<script>
import UserStore from "../../../store/UserStore"

import DateRangeSelector from "../../../components/app/DateRangeSelector"

import axios from 'axios'
import DailyFuelInvEditForm from './DailyFuelInvEditForm.vue'
import DailyFuelInventoryPdf from './DailyFuelInventoryPDF.vue'



export default {

    components: {
        // dataLoading,
        DateRangeSelector,
        DailyFuelInvEditForm,
        DailyFuelInventoryPdf
    },

    metaInfo: {
        title: 'Daily Fuel Inventory Reconciliation Report'
    },

    data() {
        return {
            isShowPDFDialog: false,
            isShowEditDialog: false,
            fuelInventory: [],
            fuelInvEdit: {},
            rowEditing: {},
            groupedInventory: [],
            groupColumn: "product",
            user: [],
            isPaginated: false,
            perPage: 32,
            isLoading: true,
            isFullPage: false,
            dateRange: [],
            productTotals: [],
            allowEdit: true,
            decPoints: 3
        }
    },

    methods: {

        fetchFuelInventory() {
            if (this.user.activeStore) {
                this.isLoading = true
                this.groupedInventory = []
                this.fuelInventory = []
                var startDate = this.dateRange[0].toISOString().split('T')[0] 
                var endDate = this.dateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/tanks/fuelReconciliation?product=A'
                            + '&fromDate=' + startDate
                            + '&toDate=' + endDate
                            
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.fuelInventory = response.data.data
                        this.groupByProduct()

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response) {
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        } else {
                            console.log(err)
                        }
                        
                    })
            }            
        },

        groupByProduct() {

            this.groupedInventory = this.groupArrayByProp(this.fuelInventory, "product")

            this.groupedInventory.forEach(product => {
                product.forEach(day => {
                    day.netOpenVol = (day.previousDayManualEndingVolume	? day.previousDayManualEndingVolume : day.startingVolume)
                    day.netCloseVol = (day.manualEndingVolume ? this.getNumber(day.manualEndingVolume) : this.getNumber(day.endingVolume))

                    day.netBalanceVol = day.netOpenVol + day.deliveredVolume - day.soldVolume
                    
                    day.netVarianceVol = day.netCloseVol - day.netBalanceVol 

                    day.netOpenVolPOS = (day.previousDayManualEndingVolumePOS ? this.getNumber(day.previousDayManualEndingVolumePOS) : this.getNumber(day.beginningVolumePOS))
                    
                    day.netDeliveryVolPOS = (day.manualDeliveryVolumePOS ? this.getNumber(day.manualDeliveryVolumePOS) : this.getNumber(day.deliveredVolumePOS))
                    day.netCloseVolPOS = (day.manualEndingVolumePOS ? this.getNumber(day.manualEndingVolumePOS) : this.getNumber(day.endingVolumePOS))
                    
                    day.netVarianceVolPOS = day.netCloseVolPOS - 
                        (day.netOpenVolPOS + 
                        day.netDeliveryVolPOS -
                        day.soldVolume)                                
                })                
            } )

            this.calcProductTotals()

        },

        calcProductTotals() {
            this.productTotals = []

            this.groupedInventory.forEach(item => {

                this.productTotals.push({
                    "product": item.product,
                    "totalNetDeliveryVol": item.reduce((accum,item) => accum + item.deliveredVolume, 0),
                    "totalSoldVolume": item.reduce((accum,item) => accum + parseFloat(item.soldVolume), 0),
                    "totalNetDeliveryVolPOS": item.reduce((accum,item) => accum + parseFloat(item.netDeliveryVolPOS), 0),
                    "totalNetCloseVolPOS": item.reduce((accum,item) => accum + parseFloat(item.netCloseVolPOS), 0),
                    "showPOSData": true
                })


                this.productTotals[this.productTotals.length - 1].firstDayOpenVol = item[0].netOpenVol
                
                this.productTotals[this.productTotals.length - 1].lastDayCloseVol = item[item.length - 1].netCloseVol

                this.productTotals[this.productTotals.length - 1].totalNetBalance =  item[0].netOpenVol +
                    this.productTotals[this.productTotals.length - 1].totalNetDeliveryVol -
                    this.productTotals[this.productTotals.length - 1].totalSoldVolume 

                this.productTotals[this.productTotals.length - 1].totalNetVariance = this.productTotals[this.productTotals.length - 1].lastDayCloseVol - 
                    this.productTotals[this.productTotals.length - 1].totalNetBalance 

                this.productTotals[this.productTotals.length - 1].firstDayOpenVolPOS = item[0].netOpenVolPOS
                this.productTotals[this.productTotals.length - 1].lastDayCloseVolPOS = item[item.length - 1].netCloseVolPOS

                this.productTotals[this.productTotals.length - 1].totalNetBalancePOS =  item[0].netOpenVolPOS +
                    this.productTotals[this.productTotals.length - 1].totalNetDeliveryVolPOS -
                    this.productTotals[this.productTotals.length - 1].totalSoldVolume 

                this.productTotals[this.productTotals.length - 1].totalNetVariancePOS = this.productTotals[this.productTotals.length - 1].lastDayCloseVolPOS - 
                    this.productTotals[this.productTotals.length - 1].totalNetBalancePOS 
                

            })

            this.productTotals.forEach(prod => {
                if ( prod.totalNetDeliveryVolPOS || prod.totalNetCloseVolPOS) {
                    prod.showPOSData = true
                } else {
                    prod.showPOSData = false
                }
            })
        },

        calcProductTotals2() {
            this.productTotals = []
            this.groupedInventory.forEach(item => {
                this.productTotals.push({
                    "product": item.product,
                    "totalNetDeliveryVol": item.reduce((accum,item) => accum + item.deliveredVolume, 0),
                    "totalSoldVolume": item.reduce((accum,item) => accum + parseFloat(item.soldVolume), 0),
                    "totalManualEndingVolume": item.reduce((accum,item) => accum + parseFloat(item.manualEndingVolume), 0),
                    "totalManualDeliveredVolumePOS": item.reduce((accum,item) => accum + parseFloat(item.manualDeliveryVolumePOS), 0),
                    "totalManualEndingVolumePOS": item.reduce((accum,item) => accum + parseFloat(item.manualEndingVolumePOS), 0),
                    "totalEndingVolumePOS": item.reduce((accum,item) => accum + (item.manualEndingVolumePOS ? item.manualEndingVolumePOS : item.endingVolumePOS), 0),
                    "totalDeliveredVolumePOS": item.reduce((accum,item) => accum + (item.manualDeliveryVolumePOS ? item.manualDeliveryVolumePOS : item.deliveredVolumePOS), 0),
                    "showPOSData": true
                })
            })

            this.productTotals.forEach(prod => {
                if ( prod.totalEndingVolumePOS || prod.totalDeliveredVolumePOS) {
                    prod.showPOSData = true
                } else {
                    prod.showPOSData = false
                }
            })
        },       

        setDateRange(dateRange) {
            this.dateRange = [dateRange[0], dateRange[1]]
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        doAction(row) {
            this.isShowEditDialog = true
            this.rowEditing = row
            this.fuelInvEdit = Object.assign({}, row)
        },

        saveEditRecord() {
            this.rowEditing.manualEndingVolume = this.fuelInvEdit.manualEndingVolume
            this.rowEditing.manualDeliveryVolumePOS = this.fuelInvEdit.manualDeliveryVolumePOS
            this.rowEditing.manualEndingVolumePOS = this.fuelInvEdit.manualEndingVolumePOS

            this.updateFuelInventory()
                .then(() => {
                    this.hideDialog()
                })
        },

        async updateFuelInventory() {

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        '/tanks/dailyrecon' 
            axios.put(url,
                this.rowEditing,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.fetchFuelInventory()
                    
                    this.$buefy.toast.open({
                            message: 'Fuel Inventory Updated!',
                            type: 'is-success'
                        })
                })
                .catch( (error) => {
                    if (error.response.status === 400) {
                        
                        this.$buefy.toast.open({
                            message: 'Failed to update expense payment status' + error.message,
                            type: 'is-warning'
                        })
                    }
                }) 

        },

        hideDialog() {
            this.isShowEditDialog = false
        },

        showPOSData(index) {
            return this.productTotals[index].showPOSData
        },

        netOpenVolPOS(row) {
            var temp = (row.previousDayManualEndingVolumePOS ? parseFloat(row.previousDayManualEndingVolumePOS) : parseFloat(row.beginningVolumePOS))
            return (temp ? temp : 0)
        },

        netDeliveryVolPOS(row) {
            var temp =  (row.manualDeliveryVolumePOS ? parseFloat(row.manualDeliveryVolumePOS) : parseFloat(row.deliveredVolumePOS))
            return (temp ? temp : 0)
        },

        netCloseVolPOS(row) {
            var temp =  (row.manualEndingVolumePOS ? parseFloat(row.manualEndingVolumePOS) : parseFloat(row.endingVolumePOS))
            return (temp ? temp : 0)
        },

        netVarianceVolPOS(row) {
            var temp =  this.netOpenVolPOS(row) - row.soldVolume + this.netDeliveryVolPOS(row) - this.netCloseVolPOS(row)
            return (temp ? temp : 0)
        },

    },

    computed: {

     
    },

    watch: {
        dateRange: function() {
            this.fetchFuelInventory()
        }

    },

    mounted() {
        this.user = this.getUser()

        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.dateRange=[firstDay, lastDay]   


        // this.fetchFuelInventory()
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>