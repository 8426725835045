<template>
    <div class="section">
    
        <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
            <div class="card popupDialog">
                <div class="card-content">
                        <iframe id="pdf" class="pdfiFrame">
                            Your browser doesn't support iframes
                        </iframe> 
                </div>
            </div>
        </b-modal>        
    
    </div>
    <!-- <div class="box is-danger" v-else><div class="hero title">Not authorized</div></div> -->
    </template>
    
    <script>
    
    import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
    // import { PageSizes } from 'pdf-lib'
    // import { degrees } from 'pdf-lib'
    // import { rgb } from 'pdf-lib'
    import moment from 'moment'
    import UserStore from "../../../store/UserStore"
    // import { text } from 'body-parser'
    
    export default {
        props: {
            isShowPDFDialog: Boolean,
            dailyGroup: Array,
            dateRange: Array,
            prevMonthBalance: Number
        },
    
        data() {
            return {
                pdfDoc: {},
                helveticaFont: {},
                pageNumber: 0,
                page: {},
                columnTotals: {},
                isShowPDFDialogLocal: false,
                countryCurrencyFormat: UserStore.getters.getCountryCurrencyFormat,
            }
    
        },
    
        methods: {
    
            closePDFDialog() {
                this.$emit('close-pdf-dialog')
            },
    
            createPdf: async(gthis) => {
    

                gthis.pdfDoc = await PDFDocument.create()
                gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
                gthis.pdfDoc.setTitle('Payroll Report')
                gthis.pdfDoc.setSubject('support@veecli.com')
                gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
                gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

                gthis.pngUrl = '/images/veecli-logo.png'
                gthis.pngImageBytes = await fetch(gthis.pngUrl).then((res) => res.arrayBuffer())
    
                gthis.pngImage = await gthis.pdfDoc.embedPng(gthis.pngImageBytes)
                gthis.pngDims = gthis.pngImage.scale(0.08)
    
                gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)
    
                await gthis.pageHeader(gthis)
                // var text = ""
                var row = 480
                row -= 10

                // Clear Column Totals
                gthis.columnTotals.cashAmount = 0
                gthis.columnTotals.cashRefundAmount = 0
                gthis.columnTotals.netLottery = 0
                gthis.columnTotals.totalCashIncome = 0
                gthis.columnTotals.totalCashExpense = 0
                gthis.columnTotals.safedropCashAmount = 0
                gthis.columnTotals.cashActualOnhand = 0
                gthis.columnTotals.cashOverShort = 0
                gthis.columnTotals.bankDepositGeneral = 0
                gthis.columnTotals.bankDepositLottery = 0
                gthis.columnTotals.bankDepositAtm = 0
                gthis.columnTotals.cashLoadedInAtm = 0
                gthis.columnTotals.netCashOnHand = 0
                gthis.columnTotals.previousCashUsed = 0
                gthis.columnTotals.checkActualOnhand = 0

                gthis.dailyGroup.forEach(day => {
                    if (row < 80) {
                        gthis.pageHeader(gthis)
                        row = 480
                    }

                    day.shifts.forEach(shift => {
                        row -= 20
                        gthis.drawTextLeft(gthis, row, 25, 8, moment(shift.periodBeginDate).format('MM/DD HH:mm'))
                        gthis.drawTextRightCurrency(gthis, row, 120, 9, shift.cashAmount)   
                        gthis.drawTextRightCurrency(gthis, row, 175, 9, shift.cashRefundAmount * -1)   
                        gthis.drawTextRightCurrency(gthis, row, 230, 9, shift.netLottery)   
                        gthis.drawTextRightCurrency(gthis, row, 285, 9, shift.totalCashIncome)   
                        gthis.drawTextRightCurrency(gthis, row, 340, 9, (shift.totalCashExpense - shift.previousCashUsed) * -1)   
                        gthis.drawTextRightCurrency(gthis, row, 395, 9, shift.safedropCashAmount)   
                        gthis.drawTextRightCurrency(gthis, row, 450, 9, shift.cashActualOnhand)  
                        var shiftOverShort = shift.cashAmount -
                            shift.cashRefundAmount +
                            shift.netLottery +
                            shift.totalCashIncome -
                            shift.totalCashExpense -
                            shift.safedropCashAmount -
                            shift.cashActualOnhand -
                            shift.previousCashUsed
                        gthis.drawTextRightCurrency(gthis, row, 505, 9, shiftOverShort * -1)                        
                         
                        // gthis.drawTextRightCurrency(gthis, row, 505, 9, shift.cashOverShort)   
                        gthis.drawTextRightCurrency(gthis, row, 560, 9, shift.bankDepositGeneral)   
                        gthis.drawTextRightCurrency(gthis, row, 615, 9, shift.bankDepositLottery)   
                        gthis.drawTextRightCurrency(gthis, row, 670, 9, shift.bankDepositAtm)   
                        gthis.drawTextRightCurrency(gthis, row, 710, 9, shift.cashLoadedInAtm)
                        gthis.drawTextRightCurrency(gthis, row, 770, 9, shift.netCashOnHand)  
                        if (row < 80) {
                            gthis.pageHeader(gthis)
                            row = 480
                        }
                    })                    
                              
                    row -= 20
                    gthis.drawTextLeft(gthis, row, 20, 8, moment(day.salesDate).format('MM/DD/YYYY'))
                    gthis.drawTextRightCurrency(gthis, row, 120, 9, day.cashAmount)   
                    gthis.drawTextRightCurrency(gthis, row, 175, 9, day.cashRefundAmount * -1)   
                    gthis.drawTextRightCurrency(gthis, row, 230, 9, day.netLottery)   
                    gthis.drawTextRightCurrency(gthis, row, 285, 9, day.totalCashIncome)   
                    gthis.drawTextRightCurrency(gthis, row, 340, 9, (day.totalCashExpense - day.previousCashUsed) * -1)   
                    gthis.drawTextRightCurrency(gthis, row, 395, 9, day.safedropCashAmount)   
                    gthis.drawTextRightCurrency(gthis, row, 450, 9, day.cashActualOnhand)
                    var dayOverShort = day.cashAmount -
                        day.cashRefundAmount +
                        day.netLottery +
                        day.totalCashIncome -
                        day.totalCashExpense -
                        day.safedropCashAmount -
                        day.cashActualOnhand -
                        day.previousCashUsed
                    gthis.drawTextRightCurrency(gthis, row, 505, 9, dayOverShort * -1)                        
                    // gthis.drawTextRightCurrency(gthis, row, 505, 9, day.cashOverShort)   
                    gthis.drawTextRightCurrency(gthis, row, 560, 9, day.bankDepositGeneral)   
                    gthis.drawTextRightCurrency(gthis, row, 615, 9, day.bankDepositLottery)   
                    gthis.drawTextRightCurrency(gthis, row, 670, 9, day.bankDepositAtm)   
                    gthis.drawTextRightCurrency(gthis, row, 710, 9, day.cashLoadedInAtm)
                    gthis.drawTextRightCurrency(gthis, row, 770, 9, day.netCashOnHand)  
                    gthis.drawTotalRectangle(gthis, row, 20, 760, 16, 0.1)
                    row -= 7
                    
                    // Add to Totals
                    gthis.columnTotals.cashAmount +=day.cashAmount
                    gthis.columnTotals.cashRefundAmount +=day.cashRefundAmount
                    gthis.columnTotals.netLottery +=day.netLottery
                    gthis.columnTotals.totalCashIncome +=day.totalCashIncome
                    gthis.columnTotals.totalCashExpense +=day.totalCashExpense
                    gthis.columnTotals.safedropCashAmount +=day.safedropCashAmount
                    gthis.columnTotals.cashActualOnhand +=day.cashActualOnhand
                    gthis.columnTotals.checkActualOnhand +=day.checkActualOnhand
                    gthis.columnTotals.cashOverShort += (day.cashOverShort - day.checkActualOnhand)
                    gthis.columnTotals.bankDepositGeneral +=day.bankDepositGeneral
                    gthis.columnTotals.bankDepositLottery +=day.bankDepositLottery
                    gthis.columnTotals.bankDepositAtm +=day.bankDepositAtm
                    gthis.columnTotals.cashLoadedInAtm +=day.cashLoadedInAtm
                    gthis.columnTotals.previousCashUsed +=day.previousCashUsed
                    gthis.columnTotals.netCashOnHand = day.netCashOnHand
                })

                row -= 20

                // Print Totals
                gthis.drawTextLeft(gthis, row, 20, 8, "Total")
                gthis.drawTextRightCurrency(gthis, row, 120, 9, gthis.columnTotals.cashAmount)   
                gthis.drawTextRightCurrency(gthis, row, 175, 9, gthis.columnTotals.cashRefundAmount * -1)   
                gthis.drawTextRightCurrency(gthis, row, 230, 9, gthis.columnTotals.netLottery)   
                gthis.drawTextRightCurrency(gthis, row, 285, 9, gthis.columnTotals.totalCashIncome)   
                gthis.drawTextRightCurrency(gthis, row, 340, 9, gthis.columnTotals.totalCashExpense * -1)   
                gthis.drawTextRightCurrency(gthis, row, 395, 9, gthis.columnTotals.safedropCashAmount)   
                gthis.drawTextRightCurrency(gthis, row, 450, 9, gthis.columnTotals.cashActualOnhand)
                var totalOverShort = gthis.columnTotals.cashAmount -
                    gthis.columnTotals.cashRefundAmount +
                    gthis.columnTotals.netLottery +
                    gthis.columnTotals.totalCashIncome -
                    gthis.columnTotals.totalCashExpense -
                    gthis.columnTotals.safedropCashAmount -
                    gthis.columnTotals.cashActualOnhand -
                    gthis.columnTotals.checkActualOnhand
                gthis.drawTextRightCurrency(gthis, row, 505, 9, totalOverShort * -1)   
                gthis.drawTextRightCurrency(gthis, row, 560, 9, gthis.columnTotals.bankDepositGeneral)   
                gthis.drawTextRightCurrency(gthis, row, 615, 9, gthis.columnTotals.bankDepositLottery)   
                gthis.drawTextRightCurrency(gthis, row, 670, 9, gthis.columnTotals.bankDepositAtm)   
                gthis.drawTextRightCurrency(gthis, row, 710, 9, gthis.columnTotals.cashLoadedInAtm)
                gthis.drawTextRightCurrency(gthis, row, 770, 9, gthis.columnTotals.netCashOnHand + gthis.getNumber(gthis.prevMonthBalance))  
                gthis.drawTotalRectangle(gthis, row, 20, 760, 16, 0.1)
                gthis.drawTotalRectangle(gthis, row, 20, 760, 16, 0.1)

                // Summary

                var subHead = "MONTH CASH BALANCE SUMMARY"
                if (row < 250) {
                    gthis.pageHeader(gthis,subHead)
                    row = 440
                } else {
                    row -=30
                    var textWidth = gthis.helveticaFont.widthOfTextAtSize(subHead, 14);
        
                    gthis.page.drawText(subHead, { 
                        x: 400 - (textWidth/2), 
                        y: row, 
                        size: 14, 
                        })

                }

                row -= 20

                gthis.summaryItem(gthis,row, 100, "sub", "Cash Sales", gthis.columnTotals.cashAmount)
                gthis.summaryItem(gthis,row, 450, "sub", "Previous Month End Cash ("+ moment(gthis.dateRange[0]).add(-1, 'M').format('MMM YYYY') + ")" , gthis.prevMonthBalance)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Cash Refund", gthis.columnTotals.cashRefundAmount * -1)
                gthis.summaryItem(gthis,row, 450, "sub", "Total Cash from this Month", gthis.columnTotals.cashActualOnhand)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Net Lottery", gthis.columnTotals.netLottery)
                gthis.summaryItem(gthis,row, 450, "sub", "Total Safe Deposit from this Month", gthis.columnTotals.safedropCashAmount)

                row -= 20
                var totalCash = gthis.columnTotals.cashAmount -
                    gthis.columnTotals.cashRefundAmount + gthis.columnTotals.netLottery
                var totalCashAvailable = gthis.columnTotals.cashActualOnhand + 
                    gthis.columnTotals.safedropCashAmount  + gthis.prevMonthBalance                   

                gthis.summaryItem(gthis,row, 100, "total", "Total Cash", totalCash)
                gthis.summaryItem(gthis,row, 450, "total", "Total Cash Available", totalCashAvailable)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Cash Received Additional Income", gthis.columnTotals.totalCashIncome)
                gthis.summaryItem(gthis,row, 450, "sub", "Prior Cash Utilized", gthis.columnTotals.previousCashUsed * -1)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Cash Paid for Expenses/Inventory", gthis.columnTotals.totalCashExpense * -1)
                gthis.summaryItem(gthis,row, 450, "sub", "Bank Deposit - General", gthis.columnTotals.bankDepositGeneral * -1)

                row -= 20
                var totalCashBalance = totalCash + 
                    gthis.getNumber(gthis.columnTotals.totalCashIncome) -
                    gthis.getNumber(gthis.columnTotals.totalCashExpense)
                gthis.summaryItem(gthis,row, 100, "total", "Cash Balance", totalCashBalance)
                gthis.summaryItem(gthis,row, 450, "sub", "Bank Deposit - Other", gthis.columnTotals.bankDepositLottery * -1)

                row -= 20
                var netMonthCashBalance = totalCashAvailable - 
                    gthis.columnTotals.bankDepositGeneral -
                    gthis.columnTotals.bankDepositLottery -
                    gthis.columnTotals.bankDepositAtm -
                    gthis.columnTotals.cashLoadedInAtm -
                    gthis.columnTotals.previousCashUsed

                gthis.summaryItem(gthis,row, 100, "sub", "Non-cash Included in Cash Sales/Lottery", gthis.columnTotals.checkActualOnhand * -1)
                gthis.summaryItem(gthis,row, 450, "sub", "Cash Disbursments", gthis.columnTotals.bankDepositAtm * -1)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Safe Drop", gthis.columnTotals.safedropCashAmount * -1)
                gthis.summaryItem(gthis,row, 450, "sub", "Cash Loaded in ATM", gthis.columnTotals.cashLoadedInAtm * -1)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "sub", "Actual Cash On-hand", gthis.columnTotals.cashActualOnhand * -1)
                // gthis.summaryItem(gthis,row, 450, "total", 'Cash Balance On Hand from this Month' , netMonthCashBalance)

                row -= 20
                gthis.summaryItem(gthis,row, 100, "total", "Cash Over/Short:", totalOverShort * -1)
                gthis.summaryItem(gthis,row, 450, "total", "Total On-hand Cash Balance:", netMonthCashBalance)


                // Create PDF Document
                const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
                document.getElementById('pdf').src = pdfDataUri;
            },
    
            pageHeader: async(gthis, titleText)  => {
    
                if (!titleText) {
                    titleText = "DAILY CASH SUMMARY REPORT"
                }
                gthis.pageNumber++
    
                gthis.page = gthis.pdfDoc.addPage([PageSizes.Letter[1],PageSizes.Letter[0]]);
                
                var storeName = UserStore.getters.getActiveStore.storeName
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);
    
                gthis.page.drawText(storeName, { 
                    x: 400 - (textWidth/2), 
                    y: 550, 
                    size: 14, 
                    })
    
                var row = 530
                var reportHead = titleText
                textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 15)
    
                gthis.page.drawText(reportHead, { 
                    x: 400 - (textWidth/2), 
                    y: row, 
                    size: 15, 
                    })            
    
                    row = 515

                    var monthName = moment(gthis.dateRange[0]).format('MMMM YYYY')
                    textWidth = gthis.helveticaFont.widthOfTextAtSize(monthName, 12);

                    gthis.page.drawText(monthName, { 
                        x: 400 - (textWidth/2), 
                        y: row, 
                        size: 11, 
                        })


                row = 500

                gthis.pageFooter(gthis)  
                if (reportHead != "MONTH CASH BALANCE SUMMARY") {
                    gthis.pageSubHead(gthis,row)
                }                       
                await gthis.veecliLogo(gthis)

            },
    
            pageSubHead: async(gthis,row) => {
                row -= 5
                gthis.drawTextLeft(gthis, row, 20, 9, 'Date')
                gthis.drawTextRight(gthis, row, 120, 9, 'Cash')
                gthis.drawTextRight(gthis, row, 175, 9, 'Cash')
                gthis.drawTextRight(gthis, row, 230, 9, 'Net')
                gthis.drawTextRight(gthis, row, 285, 9, 'Cash')
                gthis.drawTextRight(gthis, row, 340, 9, 'Cash')
                gthis.drawTextRight(gthis, row, 395, 9, 'Safe')
                gthis.drawTextRight(gthis, row, 450, 9, 'Cash')
                gthis.drawTextRight(gthis, row, 505, 9, 'Over/Short')
                gthis.drawTextRight(gthis, row, 560, 9, 'Deposit')
                gthis.drawTextRight(gthis, row, 615, 9, 'Depisit') 
                gthis.drawTextRight(gthis, row, 670, 9, 'Cash') 
                gthis.drawTextRight(gthis, row, 710, 9, 'ATM') 
                gthis.drawTextRight(gthis, row, 770, 9, 'On-Hand') 
                row -= 15
                gthis.drawColHeadLeft(gthis, row, 20, 9, '  Shift Start')
                gthis.drawColHeadRight(gthis, row, 120, 9, 'Sales')
                gthis.drawColHeadRight(gthis, row, 175, 9, 'Refund')
                gthis.drawColHeadRight(gthis, row, 230, 9, 'Lottery')
                gthis.drawColHeadRight(gthis, row, 285, 9, 'Income')
                gthis.drawColHeadRight(gthis, row, 340, 9, 'Expense')
                gthis.drawColHeadRight(gthis, row, 395, 9, 'Drop')
                gthis.drawColHeadRight(gthis, row, 450, 9, 'On Hand')
                gthis.drawColHeadRight(gthis, row, 505, 9, 'Over/Short')
                gthis.drawColHeadRight(gthis, row, 560, 9, 'General')
                gthis.drawColHeadRight(gthis, row, 615, 9, 'Other') 
                gthis.drawColHeadRight(gthis, row, 670, 9, 'Disbrsmnt') 
                gthis.drawColHeadRight(gthis, row, 710, 9, 'Loaded') 
                gthis.drawColHeadRight(gthis, row, 770, 9, 'aftr Depo.')               
            },

            summaryItem(gthis, row, column, type, label, amount) {
                gthis.drawTextLeft(gthis, row, column, 10, label) 
                gthis.drawTextRightCurrency(gthis, row, column+250, 10, gthis.getNumber(amount)) 
                if (type=='total') {
                    gthis.drawTotalRectangle(gthis, row, column, 260, 21, 0.1)
                } else {
                    gthis.page.drawLine({
                        start: { x: column-2, y: row-2 },
                        end: { x: column+252, y: row-2  },
                        thickness: 0.5,
                        opacity: 0.4,
                    })                

                }
            },
    
            veecliLogo: async(gthis) => {
                
                gthis.page.drawImage(gthis.pngImage, {
                    x: 380,
                    y: 18,
                    width: gthis.pngDims.width,
                    height: gthis.pngDims.height,
                    rotate: degrees(0),
                    opacity: 0.75,
                })    
                
                gthis.page.drawText(" VEECLi.com", { 
                    x: 388, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    }) 
            },
    
            pageFooter: async(gthis) => {
    
                let timeNow = Date()
                timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')
    
                gthis.page.drawText(timeNow, { 
                    x: 20, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })
    
                var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
                gthis.page.drawText('Page ' + gthis.pageNumber, { 
                    x: 769 - textWidth, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })                    
            },
    
            getNextRow: async(gthis,row, count) => {
      
                if (!count) count = 1
                if (row > 75) {
                    return row - (5 * count)
                } else {
                    await gthis.pageHeader(gthis)
    
                    // Set Line to start records
                    return 680
                }
                
            },
    
            drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize, 
                })
                return row
            },
    
            drawRowTextLeft: async (gthis, row, text) => {
                row = await gthis.getNextRow(gthis,row, 2)
                // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
                if (text === "InsideSales") { text = "Inside Sales"}
                if (text === "BeerWine") { text = "Beer / Wine"}
                text = gthis.toTitleCase(text)
    
                gthis.page.drawText(text, { 
                    x: 45, 
                    y: row, 
                    size: 9, 
                })
                return row
            },
    
            drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRight: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawColHeadLeft:async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row+3, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                gthis.page.drawLine({
                    start: { x: colStart, y: row },
                    end: { x: colStart+textWidth+40, y: row  },
                    thickness: 1,
                    opacity: 0.4,
                })
                
            },
    
            drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row+3, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                gthis.page.drawLine({
                    start: { x: colStart - textWidth - 20, y: row },
                    end: { x: colStart, y: row  },
                    thickness: 1,
                    opacity: 0.4,
                })
                
            },
    
            drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
                gthis.page.drawText(text+" gal.", { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.countryCurrencyFormat.format(text), fontSize)
                gthis.page.drawText(gthis.countryCurrencyFormat.format(text), { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },        
    
            drawLine: async (gthis, row) => {
                // row = await gthis.getNextRow(gthis,row, 1)
                gthis.page.drawLine({
                    start: { x: 45, y: row },
                    end: { x: 500, y: row },
                    thickness: 1,
                    opacity: 0.2,
                })
                return row
            },
    
            drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
                gthis.page.drawRectangle({
                    x: colStart-4,
                    y: row-4,
                    width: width,
                    height: height,
                    rotate: degrees(0),
                    borderWidth: 0,
                    borderColor: grayscale(0.5),
                    color: rgb(0, 0, 0),
                    opacity: opacity,
                    borderOpacity: 0.75,
                })
            },
    
            async loadData() {
                this.isShowPDFDialogLocal = true
                this.createPdf(this)
                this.pageNumber = 0
            },
        },
      
    }
    </script>
    
    
    <style scoped>
    
    .popupDialog {
        /* position:absolute; */
        width:100%;
        height: 100%; 
        max-height: 1000px;
        min-height: 900px;     
    }
    
    .pdfiFrame {
        position:absolute; 
        top:0; 
        left:0; 
        bottom:0; 
        /* right:0;  */
        width:100%; 
        height:100%; 
        /* border:none; */
        /* margin-top: 50px; */
        /* margin-bottom: 50px; */
        /* padding:0;  */
        overflow:hidden; 
        /* z-index:999999;    */
    }
    div{
        height: 100%;
    }
    </style>