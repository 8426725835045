<template>
    <div class=" has-text-weight-semibold">
        <div class="is-large has-background-gray has-text-centered">

            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">CREDIT/DEBIT CARD CHARGES</div>
                    <div class="level-right has-text-centered">
                        <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                            <i class="fas fa-print"></i>
                        </b-button>
                        <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                    </div>                    
                </div>
                                
            </div>
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <b-field>
                        <date-range-selector
                            :dateRange="salesDateRange"
                            :disabled="unsavedUpdates.length > 0"
                            @set-date-range="setDateRange"
                            >
                        </date-range-selector>                    
                    </b-field>                                      
                </div>
            </div> 
        </div>
        <b-notification 
            v-if="ccVendorRecord.id"
            type="is-info is-light" 
            aria-close-label="Close notification">
            Credit Card Processor: <span class="has-text-weight-bold is-size-5 has-text-primary">{{ ccVendorRecord.name }}</span>
        </b-notification>
        <b-notification 
            v-else
            type="is-danger is-light" 
            aria-close-label="Close notification">
            No Credit Card Vendor selected in <router-link class="has-text-primary" to="/store/settings">Store Settings</router-link>. Update not allowed.
        </b-notification> 

        <VeeSavePane
            :cancelEnable="unsavedUpdates.length > 0"
            :saveEnable="unsavedUpdates.length > 0"
            :isEditing="unsavedUpdates.length > 0"
            @cancel-edit="fetchStoreSales()"
            @save-edit="saveAllUpdates()"
            >

        </VeeSavePane>
        <!-- <div class="columns save-buttons-overlay" v-show="unsavedUpdates.length > 0">
            <div class="column is-three-quarter has-text-right">
            
            </div>
            <div class="column is-one-quarter has-text-centered has-background-info-light box">
                    <div class="is-size-6 has-text-info">
                        CHANGES NOT SAVED 
                    </div>
                    <button class="button is-default" type="button" @click="fetchStoreSales" :disabled='unsavedUpdates.length == 0'>Cancel</button>
                    &nbsp;&nbsp;
                    <button class="button is-success" type="button" @click="saveAllUpdates" :disabled='unsavedUpdates.length == 0'>Save</button>
            </div>   
        </div> -->
        <br>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Credit Card</p>
                    <p class="is-size-6"> {{ totalCreditCharges | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Debit Card</p>
                    <p class="is-size-6"> {{ totalDebitCharges | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Charges</p>
                    <p class="is-size-6"> {{ totalCharges | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Fees</p>
                    <p class="is-size-6"> {{ totalFees | formatCurrency }}</p>
                    </div>
                </div>  

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Collected Amount</p>
                    <p class="is-size-6"> {{ netCollected | formatCurrency }}</p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Outstanding Amount</p>
                    <p class="is-size-6" :class="{'has-text-danger': (notCollected)  > 0.00 }"> {{ notCollected | formatCurrency }}</p>
                    </div>
                </div> 
            </div>
        </div>
        <b-table v-if="dailySales"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='dailySales'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            :row-class="getRowClass"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="date"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            >

            <template>
           
                <b-table-column class="is-2" field="date" label="Date" v-slot="props" sortable sorticon>
                    {{ props.row.date.substr(0,10) | formatDate }}
                </b-table-column>
            
                <b-table-column numeric field="netSales" label="Net Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.netSales | formatCurrency}}
                </b-table-column>

                <b-table-column numeric field="creditAmount" label="Credit Card Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.creditAmount  | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="debitAmount" label="Debit Card Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.debitAmount  | formatCurrency }}
                </b-table-column>   

                <b-table-column numeric field="creditRefundAmount" label="Refund Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.creditRefundAmount  | formatCurrency }}
                </b-table-column>  

                <b-table-column numeric field="totalAmount" label="Credit & Debit Total" align="right" v-slot="props" sortable sorticon>
                    {{ debitCreditTotal(props.row) | formatCurrency }}
                </b-table-column> 

                <b-table-column numeric field="creditCardFees" label="Credit Card Fees" align="right" v-slot="props" sortable sorticon>
                    <VeeInput 
                        type="currency"
                        v-model="props.row.creditCardFeesUpdated"
                        name="creditCardFeesUpdated"
                        :value="props.row.creditCardFeesUpdated"
                        :countryCurrencyCode="countryCurrencyCode"
                        :editable="isEditable"
                        />
                </b-table-column>                

                <b-table-column numeric field="collectedAmount" label="Net Amount" align="right" v-slot="props" sortable sorticon>
                    {{ collectedAmount(props.row) | formatCurrency }}
                </b-table-column>

                <!-- <b-table-column v-slot="props" class="is-2 has-text-right" field="amount" label="" sortable sorticon>
                    <b-button 
                        class="is-success is-small"
                        :disabled="disableEdit(props.row)" 
                        v-on:click="saveRecord(props.row)" 
                        rounded>
                        Save
                    </b-button>
                </b-table-column>    -->
            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>
        <credit-card-fees-PDF-report
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :filteredRecords="dailySales"
            :dateRange="salesDateRange"
            @close-pdf-dialog="togglePDFDialog"
        >

        </credit-card-fees-PDF-report>
       
    </div>
</template>

<script>

import UserStore from "../../../../store/UserStore"
import DataLoading from '../../../../components/app/DataLoading'
import axios from 'axios'
import DateRangeSelector from "../../../../components/app/DateRangeSelector"
import VeeInput from "../../../../components/app/VeeInput"
import VeeSavePane from "../../../../components/app/VeeSavePane.vue"
import moment from 'moment'
import CreditCardFeesPDFReport from "./CreditCardFeesPDFReport"

export default {
    
    beforeRouteLeave (to, from, next) {
        
        if (this.unsavedUpdates.length > 0) {
            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => next()
            })
        } else {
            next()
        }
    },

    components: {
        DataLoading,
        DateRangeSelector,
        CreditCardFeesPDFReport,
        VeeInput,
        VeeSavePane
    },

    metaInfo: {
        title: 'Credit Card Fees'
    },

    data() {
        return {
            users: [],
            showNumberControls: false,
            departmentMappingTotals: [],
            dailySales: [],
            fuelProducts: [],
            deptSales: [],
            totalSales: {},
            isScrollable: true,
            isPaginated: false,
            showDetailIcon: true,
            perPage: 50,
            isLoading: true,
            isFullPage: true,
            isShowPDFDialog: false,
            storeId: 0,
            salesDateRange: [new Date(), new Date()],
            mapTotal: 0.00,
            ccVendor: {}, 
            isEditable: false,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            ccVendorRecord: {
                id: 0,
                name: ""
            },
            salesFields: [
                    'date',
                    'creditAmount',
                    'debitAmount',
                    'lineCardAmount',
                    'netSales',
                    'totalTaxes',
                    'totalSalesTaxes',
                    'totalRefundTaxes',
                    'cancelRefund',
                    'cashRefundAmount',
                    'voidLineAmount',
                    'totalPaymentOut',
                    'payOut',
                    'payIn',
                    'merchSales',
                    'fuelSales',
                    'cashAmount',
                    'creditCardFees',
                    'creditRefundAmount'
                    ],
            chartOptions: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },        
        } 

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchStoreSales()
            } else {
                this.isLoading = false
            }
        }

    },

    methods: {

        fetchVendor() {
            if (this.ccVendor.value > 0) {
                const url = process.env.VUE_APP_API_ROOT_URL + 'vendors/' + this.ccVendor.value
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.ccVendorRecord = response.data.data
                        if (this.ccVendorRecord.id) {
                            this.isEditable = true
                        }
                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        if (this.ccVendorRecord.id) {
                            this.isEditable = false
                        }
                        this.isLoading = false

                    })                
            }
        },

        fetchStoreSales() {
            if (this.user.activeStore) {

                this.totalSales.netSales = 0.00
                this.dailySales = []
                this.isLoading = true
                this.dataSales = []

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                    + '/sales?size=50'  + "&fromDate="+startDate+"&toDate="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.loadDailySales(Object.entries(response.data.data))

                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        
                        this.isLoading = false

                    })
            }
            
        },

        getRowClass(row) {

            // if (row.creditCardFeesUpdated != row.creditCardFees) {
            //     return 'has-background-warning-light'
            // }
            if (row.creditCardFees == 0 && row.creditCardFeesUpdated == 0) {
                return 'has-background-warning-light'
            }
        },   
        
        // cancelAllUpdates() {
        //     this.$buefy.dialog.confirm({
        //         title: 'Discard changes!',
        //         message: 'All changes to the shift will be lost.  Are you sure?',
        //         type: 'is-danger',
        //         hasIcon: true,
        //         cancelText: "Keep Changes",
        //         confirmText: "Discard Changes",
        //         onConfirm: () => {
        //             this.fetchStoreSales()
        //             UserStore.commit("setIsEditing",false)
        //             this.$buefy.toast.open({
        //                 message: 'Shift changes have been discarded and loaded saved data', 
        //                 type: 'is-info'
        //             })
        //         }
        //     })
        // },

        saveAllUpdates() {

            if (this.unsavedUpdates.length == 0) {
                return
            }

            var toSaveRecords = []

            this.isLoading = true

            this.unsavedUpdates.forEach( item => {
                var saveItem = {
                    "salesId": item.salesId,
                    "creditCardFees": item.creditCardFeesUpdated,
                    "userId":  this.user.id,
                    "vendorId": this.ccVendor.value
                }
                toSaveRecords.push(saveItem)
            })
            
            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                + '/sales/creditcardfees'

            axios.put(url,
                toSaveRecords,
                {   
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
  
                    // row.creditCardFees = row.creditCardFeesUpdated
                    this.$buefy.toast.open({
                        message: "Saved Credit Card Fees",
                                type: 'is-success'
                    }) 
                    this.fetchStoreSales() 
                })
                .catch( (err) => {
                    if (err.response) {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }                            
                    } else {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    }
                    this.$buefy.toast.open({
                        message: "Failed to save Credit Card Fees",
                                type: 'is-danger'
                    })                    
                    this.isLoading = false

                })    

            this.isLoading = false
        },

        saveRecord(row) {

            if (row.creditCardFees == row.creditCardFeesUpdated) {
                return
            }

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                + '/sales/creditcardfees'

            axios.put(url,
                [{
                    "salesId": row.salesId,
                    "creditCardFees": row.creditCardFeesUpdated,
                    "userId":  this.user.id,
                    "vendorId": this.ccVendor.value
                }],
                {   
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
  
                    row.creditCardFees = row.creditCardFeesUpdated
                    this.$buefy.toast.open({
                        message: "Saved Credit Card Fees - Date: " + this.formatDate(row.date) + " Before: " + this.formatCurrency(row.creditCardFees) + " After: " + this.formatCurrency(row.creditCardFeesUpdated),
                                type: 'is-success'
                    })  
                })
                .catch( (err) => {
                    this.isLoading = false
                    if (err.response) {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }                            
                    } else {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    }
                    this.$buefy.toast.open({
                        message: "Failed to save Credit Card Fees - Date: " + this.formatDate(row.date) + " Fees: " + this.formatCurrency(row.creditCardFeesUpdated),
                                type: 'is-danger'
                    })                    
                    this.isLoading = false

                })            

        },

        loadDailySales(items) {
            
            this.isLoading = true

            var item

            this.totalSales = []

            this.dailySales = []

            for (item of items) {

                if (! item[1].netSalesDTO.netSales) {
                    continue
                }

                let itemObj = Object.keys(item[1].netSalesDTO)
                    .filter(key => this.salesFields.indexOf(key) >= 0)
                    .reduce((itemObj, key) => Object.assign(itemObj, { [key]: item[1].netSalesDTO[key] }), {})

                itemObj.otherPayment = itemObj.netSales - itemObj.creditAmount - itemObj.debitAmount - itemObj.lineCardAmount - itemObj.cashAmount + itemObj.cancelRefund

                if (itemObj.creditAmount == null) {
                    itemObj.creditAmount = 0
                }
                if (itemObj.debitAmount == null) {
                    itemObj.debitAmount = 0
                }

                if (itemObj.creditCardFees == null) {
                    itemObj.creditCardFees = 0
                }
                
                if (itemObj.creditRefundAmount == null) {
                    itemObj.creditRefundAmount = 0
                }
                itemObj.creditCardFeesUpdated = itemObj.creditCardFees
                itemObj.salesId = item[1].netSalesDTO.id

                this.dailySales.push(itemObj)
            }

            this.dailySales.sort(function (a, b) {
                return a.date.localeCompare(b.date)
            });

            this.isLoading = false

            if (this.dailySales.length > 40) {
                this.isPaginated = true
            } else {
                this.isPaginated = false
            }

        },

        applyFilter() {
            this.fetchStoreSales()
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },
        
        disableEdit(row) {
            return row.creditCardFees == row.creditCardFeesUpdated || this.ccVendorRecord.name == ""
        },
        
        debitCreditTotal(row) {
            return row.creditAmount + row.debitAmount -  row.creditRefundAmount
        },

        collectedAmount(row) {
            return (row.creditCardFeesUpdated ? this.debitCreditTotal(row) - row.creditCardFeesUpdated : this.debitCreditTotal(row) - row.creditCardFees)           
        },
        
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY')
        },

        formatCurrency(num) {
            return num = new Intl.NumberFormat(`en-US`, {
                    currency: `USD`,
                    style: 'currency',
                }).format(num);

        },   

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        beforeLeavingPage(event) {
            
            if (this.unsavedUpdates.length > 0) {          
                event.preventDefault()
                event.returnValue = ""
            }
    
        }, 
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        columnTotals() {
            return [
                100, 200, 300, 400
            ];
        },

        totalCreditCharges() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.creditAmount), 0)
        },

        totalDebitCharges() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.debitAmount), 0)
        },
        
        totalCharges() {
            return this.totalCreditCharges + this.totalDebitCharges
        },

        totalFees() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.creditCardFees), 0)
        },

        notCollected() {
            var collectedRecs = this.dailySales.filter(item => (item.creditCardFees == 0)) 
            return collectedRecs.reduce((accum,item) => accum + parseFloat(item.creditAmount) + parseFloat(item.debitAmount), 0)
        },

        netCollected() {
            return this.totalCreditCharges + this.totalDebitCharges - this.notCollected - this.totalFees
        },
        
        unsavedUpdates() {
            return this.dailySales.filter(item => (item.creditCardFeesUpdated != item.creditCardFees))            
        }

    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        
        this.ccVendor = UserStore.getters.getCreditCardVendor
        this.fetchVendor()

    },

    created: function () {
        window.addEventListener('beforeunload',this.beforeLeavingPage)
    },
  
}
</script>

<style>

/* .scrollable {
    width: 90vw !important;
    padding-right: 10px;
} */
.fullwidth {
    width: 100%;
    
}
.boxheader {
    background-color: rgb(235, 244, 252);
}
.bordertop {
    border-top: 2px solid gray;
}
.borderbottom {
    border-bottom: 2px solid gray;
    border-color: lightgray;
}
.databox {
    background-color:aliceblue;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 5px #888888;
    vertical-align: top;
}
.topalign {
    vertical-align: top;
}
.box {
    margin: 5px;
}

.fees-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 100px;
    border-color: gold;
    border-width: 2px;
}

.fees-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.save-buttons-overlay {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 10;
  top: 0;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

</style>