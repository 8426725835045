<template>
  <div class="level">
    <b-loading v-model="isLoading" >
        <b-icon
            pack="fas"
            icon="spinner"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
    </b-loading>
    <div class="level-item" v-if="!isLoading">

        <div class="card">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">{{ formHeading }}</h4>
        </header>
        <div class="card-content">  
            <form ref="form" @submit.prevent="handleSubmit"  v-if="showSubmit" autocomplete="off">
                <b-field label="Email"
                    v-if="showEmailInput"
                    message="Please enter a valid email address">
                    <b-input 
                        type="email"
                        name="email"
                        icon-pack="fas"
                        icon="envelope"
                        v-model="email"
                        ref="EmailInput"
                        maxlength="100"
                        >
                    </b-input>
                    <!-- &nbsp;&nbsp;
                        :custom-class="(isBrowserAuthorizedForUser ? 'is-success' : 'is-danger')"
                        :icon-right="(isBrowserAuthorizedForUser ? 'check' : 'xmark')"
                        autocomplete="off"
                        @blur="authorizeUserToUseBrowser"                   
                    <b-icon v-if="isBrowserAuthorizedForUser"
                        icon="check"
                        size="is-small"
                        type="is-success">
                    </b-icon> -->
                </b-field>
                
                <b-tooltip v-if="key" class="is-info" position="is-right" :always="isToolTipActive" animated multilined>
                    <div class="has-text-weight-bold">Password: &nbsp;<i class="fas fa-info-circle has-text-info"></i></div>
                    <template v-slot:content>
                        <div class="panel">
                            <div class="is-underlined has-text-danger has-background-warning">PASSWORD REQUIREMENTS</div>
                            <ul>
                                <li>
                                    Minimum Length 8
                                </li>
                                <li>
                                    At least 1 Number
                                </li>
                                <li>
                                    At least 1 Uppercase Letter
                                </li>
                                <li>
                                    At least 1 Lowercase Letter
                                </li>                        
                            </ul> 
                        </div>
                    </template>
                </b-tooltip>                
         
                <b-field label="" v-if="showPasswordInput">
                    <br>                   
                    <b-input type="password"
                        icon-pack="fas"
                        icon="lock"
                        ref="PasswordInput"
                        v-model="password"
                        password-reveal
                        >
                    </b-input>
                </b-field>

                <b-field label="Repeat Password" v-if="showRepeatPasswordInput">
                    <b-input type="password"
                        icon-pack="fas"
                        icon="lock"
                        v-model="repeatPassword"
                        >
                    </b-input>
                </b-field>
                <b-field  v-if="showRepeatPasswordInput">
                    <input type="hidden" v-model="key">
                </b-field>
                <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
                <div class="columns">
                    <div class="column">
                        <button type="submit" class="button is-primary" :disabled='isDisabled'> {{ formHeading }} </button>

                    </div>
                    <div class="column has-text-right">
                        <a @click="selectForm('Forgot Password')" v-if="showPasswordInput && !showRepeatPasswordInput">Forgot Password</a>
                        <a @click="selectForm('Log in')" v-if="!showPasswordInput || showRepeatPasswordInput">Log in</a>
                    </div>

                </div>
    
            </form>

            <div class="notification is-default is-size-4"  v-if="!showSubmit">
                <ul>
                    <li v-for='item in submitResultMessage' :key="item">
                        {{ item }}
                    </li>
                </ul>
                <p class="has-text-centered"><a class="button is-primary" @click="selectForm('Log in')">Log in</a></p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import UserStore from "../../store/UserStore"
import LocalStore from "../../store/LocalStore"
import axios from 'axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import ErrorMessage from '../../components/app/ErrorMessage'
import router from './../../router'

export default {

    components: {
        'error-message': ErrorMessage
    },

    data() {
        return {
            key: "",
            email: '',
            password: '',
            repeatPassword: "",
            errors: [],
            messagetype: 'is-warning',
            accessToken: null,
            refreshToken: null,
            csrfToken: null,
            showSubmit: true,
            showEmailInput: true,
            showPasswordInput: true,
            showRepeatPasswordInput: false,
            formHeading: "Log in",
            submitResultMessage: [],
            isToolTipActive: false,
            isLoading: false
            
        }
    },

    methods: {

        handleSubmit() {

            this.isLoading = true
            
            switch (this.formHeading) {

                case "Log in":

                    this.logIn()
                    break

                case "Forgot Password":

                    this.forgotPassword()
                    break
                
                case "Reset Password":

                    this.resetPassword()
                    break

            }

        },

        selectForm(showFormTitle) {

            this.email = ""
            this.password = ""
            this.repeatPassword = ""

            switch (showFormTitle) {
                case "Forgot Password":
                    this.formHeading = showFormTitle
                    this.showEmailInput = true
                    this.showPasswordInput = false
                    this.showRepeatPasswordInput = false
                    this.showSubmit = true
                    this.submitResultMessage = []
                    break

                case "Log in":
                    this.formHeading = showFormTitle
                    this.showEmailInput = true
                    this.showPasswordInput = true
                    this.showRepeatPasswordInput = false
                    this.showSubmit = true
                    this.submitResultMessage = []
                    break

                case "Reset Password":
                    this.formHeading = showFormTitle
                    this.showEmailInput = false
                    this.showPasswordInput = true
                    this.showRepeatPasswordInput = true
                    this.showSubmit = true
                    this.submitResultMessage = []
                    break

                case "Email Sent":
                    this.formHeading = showFormTitle
                    this.showEmailInput = false
                    this.showPasswordInput = false
                    this.showRepeatPasswordInput = false
                    this.showSubmit = false
                    this.submitResultMessage = []
                    this.submitResultMessage[0] = "A validation email will be send if the email address is valid."
                    this.submitResultMessage[1] = "Please click on the link in email to reset password."
                    this.submitResultMessage[2] = "Remember to check SPAM folder if you don't find the email in Inbox."
                    this.submitResultMessage[3] = "Contact support@veecli.com for assistance."
                    break

                case "Password Reset":
                    this.formHeading = showFormTitle
                    this.showEmailInput = false
                    this.showPasswordInput = false
                    this.showRepeatPasswordInput = false
                    this.showSubmit = false
                    this.submitResultMessage = []
                    this.submitResultMessage[0] = "Your password has been reset.  Please log in."
                    this.submitResultMessage[1] = "If you are still having issues, please contact support@veecli.com."
                    this.submitResultMessage[2] = ""
                    break

                case "Reset Password Failed":
                    this.formHeading = showFormTitle
                    this.showEmailInput = false
                    this.showPasswordInput = false
                    this.showRepeatPasswordInput = false
                    this.showSubmit = false
                    this.submitResultMessage = []
                    this.submitResultMessage[0] = "Failed to reset the password.  Invalid request."
                    this.submitResultMessage[1] = "Please contact support@veecli.com."
                    this.submitResultMessage[2] = ""
                    break
            }

        },

        forgotPassword() {

            const url = process.env.VUE_APP_API_ROOT_URL + 'users/reset-password/init'
            axios.post(url,
                {
                    email: this.email
                },
            {
                headers: {
                    'correlation-id': this.$uuid.v1()
                },                      
            })
            .then( (response) => {

                this.$buefy.notification.open({
                        message: "Please check your email with a link to reset your password",
                        type: 'is-info',
                        duration: 6000,
                        'has-icon': true,
                        'icon-pack': 'fas',
                        icon: 'exclamation-circle'
                    })

                if (response) {
                    this.selectForm("Email Sent")
                }

                this.isLoading = false           
            })
            
        },

        resetPassword() {
            
            const url = process.env.VUE_APP_API_ROOT_URL + 'users/reset-password/finish'

            var crypto = require('crypto')
            const md5password = crypto.createHash('md5').update(this.password).digest("hex")

            axios.post(url,
                {
                    key: this.key,
                    newPassword: md5password
                },
            {
                headers: {
                    'correlation-id': this.$uuid.v1()
                },                      
            })
            .then( (response) => {

                this.$buefy.notification.open({
                        message: "Password Reset.  Please log in",
                        type: 'is-info',
                        duration: 6000,
                        'has-icon': true,
                        'icon-pack': 'fas',
                        icon: 'exclamation-circle'
                    })

                if (response) {
                    this.selectForm("Password Reset")
                }
                
                this.isLoading = false               
            })
            .catch( (error) => {
                this.$buefy.notification.open({
                        message: error.response.data,
                        type: 'is-danger',
                        duration: 6000,
                        'has-icon': true,
                        'icon-pack': 'fas',
                        icon: 'exclamation-circle'
                    })
                this.isLoading = false
                this.selectForm("Reset Password Failed")             
            })
            router.push({ path: '/' })
        },

        logIn() {

            const crypto = require('crypto')

            const md5password = crypto.createHash('md5').update(this.password).digest("hex")

            const url = process.env.VUE_APP_API_ROOT_URL + 'users/login'

            axios.post(url,
            {
                email: this.email,
                password: md5password,
            },
            {
                headers: {
                    'correlation-id': this.$uuid.v1()
                },                      
            })
            .then( (response) => {

                UserStore.commit('setAccessToken',response.data.accessToken)
                UserStore.commit('setRefreshToken',response.data.refreshToken)

                var user = response.data.user
                var storeUsers = user.storeUsers.filter(x => x.active)
                user.storeUsers = storeUsers

                UserStore.commit('setUser',user)

                if (user.storeUsers.length > 0) {

                    if (user.activeStore == null) {
                        if (user.storeUsers[0]) {
                            UserStore.dispatch("setActiveStoreDetails", UserStore.getters.getFirstStore)
                        }
                    } else {
                        var activeStore = user.storeUsers.find(x => x.storeId == user.activeStore)
                        if (activeStore === 'undefined') {
                            UserStore.dispatch("setActiveStoreDetails", UserStore.getters.getFirstStore)
                        } else {
                            UserStore.dispatch("setActiveStoreDetails", UserStore.getters.getActiveStore.storeId)
                        }
                    }
                }
                router.push({ path: '/' })
                    .catch(() => true)
                  
            })
            .catch(err => {
                if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                if (err.response.status === 401) {
                    this.$buefy.notification.open({
                            message: "Invalid Credentials.  Please try again!",
                            type: 'is-danger',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })
                }

                this.resetLoginForm()            
                this.isLoading = false

            })
            
        },

        resetLoginForm() {

            this.email = null
            this.password = null
            this.errors = [];
            this.remember_token = null
            this.logout()

        },

        logout() {
            UserStore.commit('userLogout')
        },

        isEmailValid() {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
        },

        authorizeUserToUseBrowser() {
            LocalStore.commit('clearUserBrowserAuth')
            if (this.isEmailValid()) {
                LocalStore.commit("setUserBrowserAuth", this.email )
            }
        },

    },

    computed: {

        isDisabled() {

            switch (this.formHeading) {

                case "Log in":
                    if ( this.isEmailValid(this.email) && this.password ) {
                        return false;
                    } else {
                        return true
                    }

                case "Forgot Password":
                    if ( this.isEmailValid(this.email) ) {
                        return false;
                    } else {
                        return true
                    }

                case "Reset Password":
                    if ( this.password && this.repeatPassword && this.password == this.repeatPassword && this.password.length > 7) {
                        return false;
                    } else {
                        return true
                    }
                   

            }
            return true

        },

        isBrowserAuthorizedForUser() {
            if (LocalStore.getters.getUserBrowserAuth(this.email)) {
                return true
            }
            return false
        }
    },

    mounted() {
        this.logout()       
        const jwt = require('jsonwebtoken')
        var secret = 'ആരാന്റെ തലയ്ക്കു ഭ്രാന്ത് പിടിച്ചാൽ കാണുന്ന തലക്ക് നല്ല ചേല്!'
        this.csrfToken = jwt.sign({page: "Login Page"}, secret, { expiresIn: '10m'})
        if (this.$route.query.key) {
            this.selectForm("Reset Password")
            this.key = this.$route.query.key
            this.isToolTipActive = true
            setTimeout(function() { 
                this.isToolTipActive = false
                this.$refs.PasswordInput.focus()
            }.bind(this), 4000)              
        } else {
            this.$refs.EmailInput.focus()
        }
    }

}
</script>

<style scoped>

.level {
    padding-top: 100px;
}

</style>