<template>
  <div class="other">
    <div class="" v-if="user.name">

      <main-dashboard></main-dashboard>

    </div>
    <div v-else>
        <!-- <section class="hero is-small is-warning mt-6" v-if="notice.message != ''">          
          <div class="hero-body">
            <p class="title">Notification</p>
            <p class="subtitle">{{ notice.message }}</p>
          </div>
        </section> -->

        <b-message class="mt-6"
            v-if="notice.message != '' && notice.active == 'true'"
            title="Notification" 
            type="is-warning" 
            aria-close-label="Close message">
            {{ notice.message }}
        </b-message>

        <guest-home></guest-home>        
    </div>
  </div>
</template>
<script>
import router from '../router'

import MainDashboard from '../views/pages/dashboard/MainDashboard'
import GuestHome from '../views/pages/dashboard/GuestHome'
import UserStore from '../store/UserStore'

export default {

  components: {

      'main-dashboard': MainDashboard,
      'guest-home': GuestHome

  },

  data() {
    return {
      apptitle: process.env.VUE_APP_URL,
      key: new Date(),
      user: {},
      userBrowser: "",
      notice: {
        "message": process.env.VUE_APP_MAINTENANCE_MESSAGE,
        "active": process.env.VUE_APP_MAINTENANCE_ACTIVE
      }
    }
  },

  methods: {

    redirectLinkedRoutes() {

      if (this.$route.query.privacy) {
        router.push('/privacy')
      }

      if (this.$route.query.login) {
        router.push('/login')
      }

      if (this.$route.query.signup) {
        router.push('/signup')
      }

      if (this.$route.query.registeruser) {
        router.push({ path: '/register-user', query: {key: this.$route.query.key }})
      }

      if (this.$route.query.passwordreset) {
        router.push({ path: '/login', query: {key: this.$route.query.key }})
      }

    }

  },

  async mounted() {
    UserStore.commit('setProgressPercent', 0)
    
    this.redirectLinkedRoutes()

    if (sessionStorage.getItem('auth')) {

        this.user = JSON.parse(sessionStorage.getItem('auth')).user

    } else {
        this.user = []
    }
  }

  
}
</script>