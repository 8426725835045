<template>
    <div class="panel has-text-weight-semibold fit-container">
            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">DAILY CASH SUMMARY REPORT</div>
                    <div class="column has-text-right is-1">
                    <b-button title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePagePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>                     
                </div>               
            </div>
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <date-range-selector
                        :dateRange="salesDateRange"
                        :showDateRangeTab="false"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>
                </div>
                <div class="column is-6">
                    <line-chart 
                        :width="chartWidth"
                        :height="chartHeight"
                        :chart-data="leftChartData" 
                        :options="chartOptions">
                    </line-chart>
                </div>                
            </div>

            <div class="panel container">
                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                    <div class="columns is-mobile">
                        <div class="level-left has-text-centered" @click="showSummaryPanel=!showSummaryPanel">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fas fa-plus-square has-text-primary" v-if="!showSummaryPanel"></i>
                            <i class="fas fa-minus-square has-text-danger" v-if="showSummaryPanel"></i>
                        </div>
                        <div class="column is-size-6 is-family-sans-serif has-text-centered"> SUMMARY </div>
                        
                    </div>
                </div>
                <div class="panel-block fit-container">
                    <div class="control columns p-6" v-if="showSummaryPanel">
                        <div class="column is-1"></div>
                        <div class="column is-5 box mr-3">
                            <div class="columns is-fullwidth is-mobile veecli-table-subhead">
                                <div class="column is-fullwidth">
                                    CASH COLLECTED SUMMARY
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Cash Sales:
                                </div>
                                <div class="column is-one-quarters has-text-right">
                                    {{ totalCashAmount | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Cash Refund:
                                </div>            
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashRefundAmount > 0.00 }">
                                    {{ (totalCashRefundAmount ) * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Net Lottery:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashExpenseAmount < 0.00 }">
                                    {{ totalNetLottery | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table-footer">
                                <div class="column is-three-quarters">
                                    Total Cash:
                                </div>
                                <div class="column is-one-quarters has-text-right"  :class="{'has-text-danger': (totalCashAmount - totalCashRefundAmount + totalNetLottery) < 0.00 }">
                                    {{ totalCashAmount - totalCashRefundAmount + totalNetLottery | formatCurrency }}
                                </div>
                            </div>    

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Additional Income Cash Received:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashIncomeAmount < 0.00 }">
                                    {{ totalCashIncomeAmount | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Expenses/Inventory Cash Paid:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashExpenseAmount > 0.00 }">
                                    {{ totalCashExpenseAmount | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table-footer">
                                <div class="column is-three-quarters">
                                    Cash Balance:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalCashAmount - totalCashRefundAmount + totalNetLottery +totalCashIncomeAmount - totalCashExpenseAmount) < 0.00 }">
                                    {{ totalCashAmount - totalCashRefundAmount + totalNetLottery +totalCashIncomeAmount - totalCashExpenseAmount  | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table" v-if="totalCheckActualOnhand != 0">
                                <div class="column is-three-quarters">
                                    Non-cash Included in Cash Sales:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCheckActualOnhand < 0.00 }">
                                    {{ totalCheckActualOnhand | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Safe Drop:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalSafedropAmount < 0.00 }">
                                    {{ totalSafedropAmount | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Actual Cash On Hand:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashActualOnhand < 0.00 }">
                                    {{ totalCashActualOnhand | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table-footer">
                                <div class="column is-three-quarters">
                                    Cash Over/Short:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashOverShort < 0.00 }">
                                    {{ totalCashOverShort | formatCurrency }}
                                </div>
                            </div>

                        </div>
                        <div class="column is-5 box ml-3">
                            <div class="columns is-fullwidth is-mobile veecli-table-subhead">
                                <div class="column is-fullwidth">
                                    CASH DISBURSMENTS/BALANCE SUMMARY
                                </div>
                            </div>                            

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Previous Month End Cash:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': prevMonthBalance < 0.00 }">
                                    {{ prevMonthBalance | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Total Cash from this Month :
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalCashActualOnhand) < 0.00 }">
                                    {{ totalCashActualOnhand | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Total Safe Deposit from this Month :
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalSafedropAmount) < 0.00 }">
                                    {{ totalSafedropAmount | formatCurrency }}
                                </div>
                            </div>                            

                            <div class="columns is-fullwidth is-mobile veecli-table-footer">
                                <div class="column is-three-quarters">
                                    Total Cash Available:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalCashActualOnhand + totalSafedropAmount + prevMonthBalance) < 0.00 }">
                                    {{ totalCashActualOnhand + totalSafedropAmount + prevMonthBalance | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Prior Cash Utilized:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalPreviousCashUsed) > 0.00 }">
                                    {{ totalPreviousCashUsed | formatCurrency }}
                                </div>
                            </div> 

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Bank Deposit - General:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalDepositGeneral > 0.00 }">
                                    {{ totalDepositGeneral * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Bank Deposit - Other:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalDepositLottery > 0.00 }">
                                    {{ totalDepositLottery * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Cash Disbursements:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalDepositATM > 0.00 }">
                                    {{ totalDepositATM * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-three-quarters">
                                    Cash Loaded in ATM:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalLoadedInAtm > 0.00 }">
                                    {{ totalLoadedInAtm * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <!-- <div class="column is-three-quarters">
                                    Balance after Deposit & Disbursments:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': totalCashOnhandThisMonth < 0.00 }">
                                    {{ totalCashOnhandThisMonth | formatCurrency }}
                                </div> -->
                            </div>


                            <div class="columns is-fullwidth is-mobile veecli-table-footer">
                                <div class="column is-three-quarters">
                                    On Hand Cash Balance:
                                </div>
                                <div class="column is-one-quarters has-text-right" :class="{'has-text-danger': (totalPeriodEndCashOnhand) < 0.00 }">
                                    {{ totalPeriodEndCashOnhand | formatCurrency }}
                                </div>
                            </div>
                            
                        </div>
                        <div class="column is-1"></div>
                    </div>
                </div>
                <div class="panel-block fit-container">
                    <div class="control"  v-if="!showSummaryPanel">
                        <div class="has-text-weight-bold">
                            <div class="columns">

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Cash Sales</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalCashAmount < 0.00 }"> {{ totalCashAmount | formatCurrency }}</p>
                                    </div>
                                </div>  

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Cash Refund</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalCashRefundAmount > 0.00 }"> {{ totalCashRefundAmount * -1 | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Net Lottery</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalNetLottery < 0.00 }"> {{ totalNetLottery | formatCurrency }}</p>
                                    </div>
                                </div>
                                
                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Cash Expense/Income</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalCashIncExpAmount < 0.00 }"> {{ totalCashIncExpAmount | formatCurrency }}</p>
                                    </div>
                                </div>
                                

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Actual Cash</p>
                                    <p class="is-size-6" :class="{'has-text-danger': (totalCashActualOnhand)  < 0.00 }"> {{ totalCashActualOnhand + totalSafedropAmount | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Over/Short</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalCashOverShort < 0.00 }"> {{ totalCashOverShort  | formatCurrency }}</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                        
                        <br>
                        <div class="control has-text-weight-bold">
                            <div class="columns">

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Deposits General</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalDepositGeneral < 0.00 }"> {{ totalDepositGeneral | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Deposits Lottery</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalDepositLottery < 0.00 }"> {{ totalDepositLottery | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Cash Disbursements</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalDepositATM < 0.00 }"> {{ totalDepositATM | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Loaded in ATM</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalLoadedInAtm < 0.00 }"> {{ totalLoadedInAtm | formatCurrency }}</p>
                                    </div>
                                </div>

                                <div class="column has-text-centered">
                                    <div>
                                        <p class="is-size-7">Opening Onhand
                                        <b-tooltip multilined label="Cash on hand and in safe from Month-End Page"
                                            position="is-left">
                                            <i class="fas fa-info-circle has-text-info"></i>
                                        </b-tooltip>
                                    </p>
                                    <p class="is-size-6" :class="{'has-text-danger': prevMonthBalance < 0.00 }"> {{ prevMonthBalance | formatCurrency }}</p>
                                    </div>
                                </div> 

                                <div class="column has-text-centered">
                                    <div>
                                    <p class="is-size-7">Closing Onhand</p>
                                    <p class="is-size-6" :class="{'has-text-danger': totalPeriodEndCashOnhand < 0.00 }"> {{ totalPeriodEndCashOnhand | formatCurrency }}</p>
                                    </div>
                                </div>                                                                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        <br>
        
        <div class="columns">
            <div class="column has-text-centered">
                <b-checkbox v-model="showNetLottery">
                    Show Net Lottery
                </b-checkbox>

                <b-checkbox v-model="showSafeDrop">
                    Show Safe Drop
                </b-checkbox>
            </div>
        </div>

        <div class="contain-scroll">
            <b-table v-if="dailyGroup"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='dailyGroup'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            :sticky-header="isStickyHeader"
            height="500"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="salesDate"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="closingDate"
            @details-open="(row) => $buefy.toast.open(`Showing Shifts for ${row.salesDate}`)"            
            >

            <template> 
                 
                <b-table-column field="salesDate" :sticky="isStickyHeader" label="Date" v-slot="props" sortable sorticon>
                    {{ props.row.salesDate.substr(0,10) | formatDate }}
                </b-table-column>
                <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props" sortable sorticon cell-class="nowrap">
                    <b-tooltip type="is-primary" position="is-right">
                        <template v-slot:content >
                            Cash Sales: 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashAmount) < 0.00 }">{{ props.row.cashAmount | formatCurrency }}</span><br>
                            Cash Refund: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashRefundAmount) > 0.00 }">{{ props.row.cashRefundAmount * -1 | formatCurrency }}</span><br>
                            <span v-if="!showNetLottery">
                                Net Lottery: (+) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.netLottery) < 0.00 }">{{ props.row.netLottery | formatCurrency }}</span><br>
                            </span>
                            <hr class="totaline">
                            Total Cash:
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery)) < 0.00 }">{{ props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery) | formatCurrency }}</span><br>
                        </template>
                        <span class="tooltip" :class="{'has-text-danger': props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery) < 0.00 }">
                            {{ props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery) | formatCurrency}}
                        </span>
                    </b-tooltip>
                </b-table-column>
                <b-table-column numeric field="netLottery" :visible="showNetLottery" label="Net Lottery" v-slot="props" sortable sorticon cell-class="nowrap">
                    <b-tooltip type="is-primary" position="is-right">
                        <template v-slot:content >
                            Instant Lottery Sales: 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.instantLottoSales) < 0.00 }">{{ props.row.instantLottoSales | formatCurrency }}</span><br>
                            Instant Lottery Payout: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.instantLottoPayout) > 0.00 }">{{ props.row.instantLottoPayout * -1 | formatCurrency }}</span><br>
                            Online Lottery Sales: (+)
                            <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLottoSales) < 0.00 }">{{ props.row.onlineLottoSales | formatCurrency }}</span><br>
                            Online Lottery Payout: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLottoPayout) > 0.00 }">{{ props.row.onlineLottoPayout * -1 | formatCurrency }}</span><br>
                            POS Included Sales or Cancel: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLotteryCanceled) > 0.00 }">{{ props.row.onlineLotteryCanceled * -1 | formatCurrency }}</span><br>
                            Machine Instant Lottery Sales : (+) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.lotteryMachineWithdraw) < 0.00 }">{{ props.row.lotteryMachineWithdraw * -1 | formatCurrency }}</span><br>
                            Non-Cash Lottery Sales  : (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.lotteryNonCashSales) > 0.00 }">{{ props.row.lotteryNonCashSales * -1 | formatCurrency }}</span><br>
                            <hr class="totaline">
                            Net Lottery:
                            <span class="tooltip" :class="{'has-text-warning': (props.row.netLottery) < 0.00 }">{{ props.row.netLottery | formatCurrency }}</span><br>
                        </template>
                        <span class="tooltip" :class="{'has-text-danger': (props.row.netLottery) < 0.00 }">
                            {{ props.row.netLottery | formatCurrency}}
                        </span>
                    </b-tooltip>
                </b-table-column>                                
                <b-table-column numeric field="totalCashIncome" label="Cash Inc/Exp" v-slot="props" sortable sorticon cell-class="nowrap">
                    <b-tooltip type="is-primary"  position="is-right">
                        <template v-slot:content >
                            Additional Income: 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashIncome) < 0.00 }">{{ props.row.totalCashIncome | formatCurrency }}</span><br>
                            Expense/Inventory Purchase: (+) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashExpense) > 0.00 }">{{ props.row.totalCashExpense * -1 | formatCurrency }}</span><br>
                            Prior Day/Shift Cash Utilized: (+)
                            <span class="tooltip" :class="{'has-text-warning': (props.row.previousCashUsed) < 0.00 }">{{ props.row.previousCashUsed | formatCurrency }}</span><br>
                            <hr class="totaline">
                            Cash Utilized from this Day:
                            <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed) < 0.00 }">{{ props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed | formatCurrency }}</span><br>
                        </template>
                        <span class="tooltip" :class="{'has-text-danger': (props.row.totalCashIncome - props.row.totalCashExpense) < 0.00 }">
                            {{ props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed | formatCurrency}}
                        </span>
                    </b-tooltip>
                </b-table-column>
                <b-table-column numeric field="safedropCashAmount" :visible="showSafeDrop" label="Cash Safedrop" v-slot="props" sortable sorticon cell-class="nowrap">
                    {{ props.row.safedropCashAmount | formatCurrency }}
                </b-table-column>                                  
                <b-table-column numeric field="cashActualOnhand" label="Cash Onhand" v-slot="props" sortable sorticon cell-class="nowrap">
                    <b-tooltip type="is-primary" position="is-right">
                        <template v-slot:content >
                            Actual Cash On hand: 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand) < 0.00 }">{{ props.row.cashActualOnhand | formatCurrency }}</span><br>
                            <span v-if="!showSafeDrop">
                                Cash Safe Deposit: 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.safedropCashAmount) < 0.00 }">{{ props.row.safedropCashAmount | formatCurrency }}</span><br>
                            </span>
                            <hr class="totaline">
                            Total Cash On hand:
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand + props.row.safedropCashAmount) < 0.00 }">{{ props.row.cashActualOnhand + props.row.safedropCashAmount | formatCurrency }}</span><br>
                            <span v-if="props.row.checkActualOnhand">
                                Non-cash On hand: 
                                <span class="tooltip">{{ props.row.checkActualOnhand | formatCurrency }}</span><br>
                            </span>
                        </template>
                        <span class="tooltip">  
                            {{ props.row.cashActualOnhand + ( showSafeDrop ? 0 : props.row.safedropCashAmount) | formatCurrency}} 
                        </span>
                    </b-tooltip>                                       
                </b-table-column> 
                <b-table-column numeric field="cashOverShort" label="Over Short" v-slot="props" sortable sorticon cell-class="nowrap">
                    <span :class="{'has-text-danger': props.row.cashOverShort > 0.00 }">{{ props.row.cashOverShort * -1 | formatCurrency }}</span>
                </b-table-column>                    
                <b-table-column numeric field="bankDepositGeneral" label="Bank Deposit General" v-slot="props" sortable sorticon cell-class="nowrap">
                    {{ props.row.bankDepositGeneral | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="bankDepositLottery" label="Bank Deposit Other" v-slot="props" sortable sorticon cell-class="nowrap">
                    {{ props.row.bankDepositLottery | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="bankDepositAtm" label="Cash Disbrsmnt" v-slot="props" sortable sorticon cell-class="nowrap">
                    {{ props.row.bankDepositAtm | formatCurrency}}
                </b-table-column>  
                <b-table-column numeric field="cashLoadedInAtm" label="Loaded ATM." sortable sorticon v-slot="props" cell-class="nowrap">
                    {{ props.row.cashLoadedInAtm | formatCurrency}}
                </b-table-column>                                              
                <b-table-column numeric field="netCashOnHand" label="Onhand After Deposit" v-slot="props" sortable sorticon cell-class="nowrap">
                    <b-tooltip type="is-primary" position="is-left">
                        <template v-slot:content >
                        <div v-if="props.row.prevCashOnHand">Previous Balance: (+)
                            <span class="tooltip" :class="{'has-text-warning': props.row.prevCashOnHand < 0.00 }">
                                {{ props.row.prevCashOnHand | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.previousCashUsed">Prior Day/Shift Cash Utilized: (-)
                            <span class="tooltip" :class="{'has-text-warning': props.row.previousCashUsed > 0.00 }">
                                {{ props.row.previousCashUsed | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.safedropCashAmount">Shift Cash Safe Drop: (+) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.safedropCashAmount) < 0.00 }">
                                {{ props.row.safedropCashAmount | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.cashActualOnhand">Shift Cash Onhand: (+)  
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand) < 0.00 }">
                                {{ props.row.cashActualOnhand | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.bankDepositGeneral">Deposit General: (-)
                            <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositGeneral) > 0.00 }">
                                {{ props.row.bankDepositGeneral | formatCurrency }}
                            </span>
                        </div> 

                        <div v-if="props.row.bankDepositLottery">Deposit Other: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositLottery) > 0.00 }">
                                {{ props.row.bankDepositLottery | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.bankDepositAtm">Disbursments: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositAtm) > 0.00 }">
                                {{ props.row.bankDepositAtm | formatCurrency }}
                            </span>
                        </div>

                        <div v-if="props.row.cashLoadedInAtm">Loaded in ATM: (-) 
                            <span class="tooltip" :class="{'has-text-warning': (props.row.cashLoadedInAtm) > 0.00 }">
                                {{ props.row.cashLoadedInAtm | formatCurrency }}
                            </span>
                        </div>

                        <hr class="totaline">

                        Total Cash Onhand : 
                        <div class="tooltip" :class="{'has-text-warning': (props.row.netCashOnHand) < 0.00 }">
                            {{ props.row.netCashOnHand  | formatCurrency}}
                        </div>
                        
                        </template>
                        <span class="tooltip" :class="{'has-text-danger': (props.row.netCashOnHand) < 0.00 }">
                        {{ props.row.netCashOnHand  | formatCurrency }}
                        </span>
                    </b-tooltip>
                </b-table-column> 
                <b-table-column numeric field="print" label="Print"  v-slot="props">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="printDaysReport(props.row)">
                        <i class="fas fa-print"></i>
                    </b-button>
                </b-table-column>                   
            </template>

            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.shifts">
                <template>
                    <b-table-column label="Shift" v-slot="props" >                         
                        <b-tooltip position="is-right" class="is-hidden-mobile" animated multilined>
                            {{ props.index + 1 }} 🕘 
                            <template v-slot:content>
                                <div class="panel">
                                    <p class="is-4 has-text-weight-bold">SHIFT {{ props.index + 1 }}</p>
                                    <p><b>Start</b>: {{ props.row.periodBeginDate | formatDateTime }}</p>
                                    <p><b>&nbsp;&nbsp;End</b>: {{ props.row.periodEndDate | formatDateTime }}</p>
                                </div>
                            </template>
                        </b-tooltip>
                        <b-tooltip position="is-left" class="is-hidden-desktop" animated multilined>
                            {{ props.index + 1 }} 🕘 
                            <template v-slot:content>
                                <div class="panel">
                                    <p class="is-4 has-text-weight-bold">SHIFT {{ props.index + 1 }}</p>
                                    <p><b>Start</b>: {{ props.row.periodBeginDate | formatDateTime }}</p>
                                    <p><b>&nbsp;&nbsp;End</b>: {{ props.row.periodEndDate | formatDateTime }}</p>
                                </div>
                            </template>
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props" cell-class="nowrap">
                        <b-tooltip type="is-primary" position="is-right">
                            <template v-slot:content >
                                Cash Sales: 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashAmount) < 0.00 }">{{ props.row.cashAmount | formatCurrency }}</span><br>
                                Cash Refund: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashRefundAmount) > 0.00 }">{{ props.row.cashRefundAmount * -1 | formatCurrency }}</span><br>
                                <span v-if="!showNetLottery">
                                    Net Lottery: (+) 
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.netLottery) < 0.00 }">{{ props.row.netLottery | formatCurrency }}</span><br>
                                </span>
                                <hr class="totaline">
                                Total Cash:
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery)) < 0.00 }">{{ props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery) | formatCurrency }}</span><br>
                            </template>
                            <span class="tooltip" :class="{'has-text-danger': (props.row.netLottery) < 0.00 }">
                                {{ props.row.cashAmount - props.row.cashRefundAmount + (showNetLottery ? 0 : props.row.netLottery) | formatCurrency}}
                            </span>
                        </b-tooltip>                        
                        <!-- {{ props.row.cashAmount |formatCurrency}} -->
                    </b-table-column>
                    <!-- <b-table-column numeric field="cashRefundAmount" label="Cash Refund" v-slot="props" sortable sorticon>
                        <span :class="{'has-text-danger': props.row.cashRefundAmount > 0.00 }">{{ (props.row.cashRefundAmount != 0 ? props.row.cashRefundAmount * -1 : 0) | formatCurrency }}</span>
                    </b-table-column>                                          -->
                    <b-table-column numeric field="netLottery" :visible="showNetLottery" label="Net Lottery" v-slot="props" sortable sorticon cell-class="nowrap">

                        <b-tooltip type="is-primary" position="is-right">
                            <template v-slot:content >
                                Instant Lottery Sales: 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.instantLottoSales) < 0.00 }">{{ props.row.instantLottoSales | formatCurrency }}</span><br>
                                Instant Lottery Payout: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.instantLottoPayout) > 0.00 }">{{ props.row.instantLottoPayout * -1 | formatCurrency }}</span><br>
                                Online Lottery Sales: (+)
                                <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLottoSales) < 0.00 }">{{ props.row.onlineLottoSales | formatCurrency }}</span><br>
                                Online Lottery Payout: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLottoPayout) > 0.00 }">{{ props.row.onlineLottoPayout * -1 | formatCurrency }}</span><br>
                                POS Included Sales or Cancel: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.onlineLotteryCanceled) > 0.00 }">{{ props.row.onlineLotteryCanceled * -1 | formatCurrency }}</span><br>
                                Machine Instant Lottery Sales : (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.lotteryMachineWithdraw) > 0.00 }">{{ props.row.lotteryMachineWithdraw * -1 | formatCurrency }}</span><br>
                                Non-Cash Lottery Sales  : (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.lotteryNonCashSales) > 0.00 }">{{ props.row.lotteryNonCashSales * -1 | formatCurrency }}</span><br>
                                <hr class="totaline">
                                Net Lottery:
                                <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed) < 0.00 }">{{ props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed | formatCurrency }}</span><br>
                            </template>
                            <span class="tooltip" :class="{'has-text-danger': (props.row.netLottery) < 0.00 }">
                                {{ props.row.netLottery | formatCurrency}}
                            </span>
                        </b-tooltip>

                        <!-- <span :class="{'has-text-danger': props.row.netLottery < 0.00 }">{{ props.row.netLottery | formatCurrency }}</span> -->
                    </b-table-column>
                    <b-table-column numeric field="totalCashIncome" label="Cash Income" v-slot="props" cell-class="nowrap">
                        {{ props.row.totalCashIncome |formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="totalCashExpense" label="Cash Expense" v-slot="props" cell-class="nowrap">
                        <span :class="{'has-text-danger': props.row.totalCashExpense > 0.00 }">

                            <b-tooltip type="is-primary" position="is-right">
                                <template v-slot:content >
                                    Additional Income: 
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashIncome) < 0.00 }">{{ props.row.totalCashIncome | formatCurrency }}</span><br>
                                    Expense/Inventory Purchase: (+) 
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashExpense) > 0.00 }">{{ props.row.totalCashExpense * -1 | formatCurrency }}</span><br>
                                    Prior Day/Shift Cash Utilized: (+)
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.previousCashUsed) < 0.00 }">{{ props.row.previousCashUsed | formatCurrency }}</span><br>
                                    <hr class="totaline">
                                    Cash Utilized from this Day:
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed) < 0.00 }">{{ props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed | formatCurrency }}</span><br>
                                </template>
                                <span class="tooltip" :class="{'has-text-danger': (props.row.totalCashIncome - props.row.totalCashExpense) < 0.00 }">
                                    {{ props.row.totalCashIncome - props.row.totalCashExpense + props.row.previousCashUsed | formatCurrency}}
                                </span>
                            </b-tooltip>
                        </span>
                    </b-table-column>
                    <b-table-column numeric field="safedropCashAmount" :visible="showSafeDrop" label="Cash Safedrop" v-slot="props" cell-class="nowrap">
                        {{ props.row.safedropCashAmount | formatCurrency }}
                    </b-table-column>                    
                    <b-table-column numeric field="cashActualOnhand" label="Cash Onhand" v-slot="props" cell-class="nowrap">
                        <b-tooltip type="is-primary" position="is-right">
                            <template v-slot:content >
                                Actual Cash On hand: 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand) < 0.00 }">{{ props.row.cashActualOnhand | formatCurrency }}</span><br>
                                <span v-if="!showSafeDrop">
                                    Cash Safe Deposit: 
                                    <span class="tooltip" :class="{'has-text-warning': (props.row.safedropCashAmount) < 0.00 }">{{ props.row.safedropCashAmount | formatCurrency }}</span><br>
                                </span>
                                <hr class="totaline">
                                Total Cash On hand:
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand + props.row.safedropCashAmount) < 0.00 }">{{ props.row.cashActualOnhand + props.row.safedropCashAmount | formatCurrency }}</span><br>
                                <span v-if="props.row.checkActualOnhand">
                                    Non-cash On hand: 
                                    <span>{{ props.row.checkActualOnhand | formatCurrency }}</span><br>
                                </span>
                            </template>
                            <span class="tooltip">  
                                {{ props.row.cashActualOnhand + ( showSafeDrop ? 0 : props.row.safedropCashAmount) | formatCurrency}} 
                            </span>
                        </b-tooltip> 
                    </b-table-column>
                    <b-table-column numeric field="cashOverShort" label="Over/Short" v-slot="props" sortable sorticon cell-class="nowrap">
                    <span :class="{'has-text-danger': props.row.cashOverShort > 0.00 }">{{ props.row.cashOverShort * -1 | formatCurrency}}</span>
                    </b-table-column>
                    <b-table-column numeric field="bankDepositGeneral" label="Deposit General" v-slot="props" sortable sorticon cell-class="nowrap">
                        {{ props.row.bankDepositGeneral | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="bankDepositLottery" label="Deposit Lottery" v-slot="props" sortable sorticon cell-class="nowrap">
                        {{ props.row.bankDepositLottery | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="bankDepositAtm" label="Cash Disbrsmnt" v-slot="props" sortable sorticon cell-class="nowrap">
                        {{ props.row.bankDepositAtm | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="cashLoadedInAtm" label="Loaded ATM." sortable sorticon v-slot="props" cell-class="nowrap">
                        {{ props.row.cashLoadedInAtm | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="netCashOnHand" label="Onhand After Deposit" v-slot="props" sortable sorticon cell-class="nowrap">
                        <b-tooltip type="is-primary" position="is-left">
                          <template v-slot:content >
                            <div v-if="props.row.prevCashOnHand">Previous Balance: (+)
                                <span class="tooltip" :class="{'has-text-warning': props.row.prevCashOnHand < 0.00 }">
                                    {{ props.row.prevCashOnHand | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.previousCashUsed">Prior Day/Shift Cash Utilized: (-)
                                <span class="tooltip" :class="{'has-text-warning': props.row.previousCashUsed > 0.00 }">
                                    {{ props.row.previousCashUsed | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.safedropCashAmount">Shift Cash Safe Drop: (+) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.safedropCashAmount) < 0.00 }">
                                    {{ props.row.safedropCashAmount | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.cashActualOnhand">Shift Cash Onhand: (+)  
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashActualOnhand) < 0.00 }">
                                    {{ props.row.cashActualOnhand | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.bankDepositGeneral">Deposit General: (-)
                                <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositGeneral) > 0.00 }">
                                    {{ props.row.bankDepositGeneral | formatCurrency }}
                                </span>
                            </div> 

                            <div v-if="props.row.bankDepositLottery">Deposit Other: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositLottery) > 0.00 }">
                                    {{ props.row.bankDepositLottery | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.bankDepositAtm">Disbursments: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.bankDepositAtm) > 0.00 }">
                                    {{ props.row.bankDepositAtm | formatCurrency }}
                                </span>
                            </div>

                            <div v-if="props.row.cashLoadedInAtm">Loaded in ATM: (-) 
                                <span class="tooltip" :class="{'has-text-warning': (props.row.cashLoadedInAtm) > 0.00 }">
                                    {{ props.row.cashLoadedInAtm | formatCurrency }}
                                </span>
                            </div>

                            <hr class="totaline">

                            Total Cash Onhand : 
                            <div class="tooltip" :class="{'has-text-warning': (props.row.netCashOnHand) < 0.00 }">
                                {{ props.row.netCashOnHand  | formatCurrency}}
                            </div>
                            
                          </template>
                          <span class="tooltip" :class="{'has-text-danger': (props.row.netCashOnHand) < 0.00 }">
                            {{ props.row.netCashOnHand  | formatCurrency }}
                          </span>
                        </b-tooltip>
                    </b-table-column>                                                                                                                                             
                </template>
              </b-table>
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>

        </div>
        
        <shift-detail-report-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :shiftDate="shiftDate"
            :hideZeroShift="hideZeroShift"
            @close-pdf-dialog="togglePDFDialog"
        >
        </shift-detail-report-pdf>
        <shift-report-pdf
            ref="pdfPageReport"
            :isShowPDFDialog="isPagePDFDialog"
            :dailyGroup="dailyGroup"
            :dateRange="salesDateRange"
            :prevMonthBalance="prevMonthBalance"
            @close-pdf-dialog="togglePagePDFDialog"
        >
        </shift-report-pdf>
    </div>  
</template>

<script>


import DateRangeSelector from "../../../components/app/DateRangeSelector"
import UserStore from "../../../store/UserStore"
import DataLoading from '../../../components/app/DataLoading'
import LineChart from '../dashboard/LineChart.js'
import moment from 'moment'

import ShiftDetailReportPdf from './ShiftDetailReportPDF'
import ShiftReportPdf from './ShiftReportPDF.vue'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        LineChart,
        DateRangeSelector,
        ShiftDetailReportPdf,
        ShiftReportPdf
    },

    metaInfo: {
        title: 'Shift Details'
    },

    data() {
        return {
            leftChartData: {},
            chartWidth: 150,
            chartHeight: 250,
            showSummaryPanel: false,
            showNetLottery: false,
            showSafeDrop: false,
            chartOptions: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                },
                responsive: true,
                maintainAspectRatio: false
            },
            chartItems: [
                {"field": "cashAmount", "label": "Cash Sales", "color": "#40E0D0"},
                {"field": "safedropCashAmount", "label":"Safe Drop", "color": "#FF7F50"},
                {"field": "cashActualOnhand", "label": "Cash Onhand", "color": "#6495ED"},
                {"field": "cashOverShort", "label": "Cash Over/Short", "color": "#f1c40f"},
                {"field": "bankDepositGeneral", "label": "Deposit-General", "color": "#338444"},
                {"field": "bankDepositLottery", "label": "Deposit-Lottery", "color": "#A52A2A"},
                {"field": "bankDepositAtm", "label": "Withdrawals", "color": "#1E90FF"},
                {"field": "cashLoadedInAtm", "label": "Loaded ATM", "color": "#2E9511"},
                {"field": "netCashOnHand", "label": "Net Onhand", "color": "#FF00FF"},
            ],
            salesDateRange: [],
            isLoading: true,
            isFullPage: true,
            isScrollable: true,
            isStickyHeader: true,
            isPaginated: false,
            showDetailIcon: true,
            perPage: 50,
            isShowPDFDialog: false,
            isPagePDFDialog: false,
            dailyShifts: [],
            dailyGroup: [],
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            month: new Date(),
            shiftDate: new Date(),
            shiftDateString: "",
            hideZeroShift: true,
            isPrintPage: false,
            shiftsData: {},
            netCashOverShort: 0,
            totalCashAmount: 0,
            totalCashRefundAmount: 0,
            totalCashIncExpAmount: 0,
            totalCashIncomeAmount: 0,
            totalCashExpenseAmount: 0,
            totalSafedropAmount: 0,
            totalPreviousCashUsed: 0,
            totalCheckActualOnhand: 0,
            totalCashActualOnhand: 0,
            totalCashOverShort: 0,
            totalOverShort: 0,
            totalDepositGeneral: 0,
            totalDepositLottery: 0,
            totalDepositATM: 0,
            totalLoadedInAtm: 0,
            totalNetLottery: 0,
            prevMonthBalance: 0,
            monthlyReportItems: [],
            monthlyReport: {},
            totalPeriodEndCashOnhand: 0,
            totalCashOnhandThisMonth: 0,
            tableNumericFields: [
                "netSales",
                "merchSales",
                "fuelSales",
                "cashOverShort",
                "cashAmount",
                "totalCashIncome",
                "totalCashExpense",
                "cashActualOnhand",
                "checkActualOnhand",
                "bankDepositGeneral",
                "bankDepositLottery",
                "bankDepositAtm",
                "cashLoadedInAtm",
                "previousCashUsed",
                "safedropAmount",
                "safedropCashAmount",
                "cashRefundAmount",
                "onlineLottoSales",
                "onlineLottoPayout",
                "instantLottoSales",
                "instantLottoPayout",
                "netLottery",
                "lotteryNonCashSales",
                "lotteryMachineWithdraw",
                "onlineLotteryCanceled",

            ],
            salesChart: {
                    labels: [],
                    datasets: [],
                    options: []
                },
            tableDetailColumns: [
                {
                        field: `netSales`,
                        label: 'Net Sales',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'netSales',
                        label: 'Merch',
                        width: '40',
                        numeric: true
                },
                {
                        field: `fuelSales`,
                        label: 'Fuel Sales',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'cashAmount',
                        label: 'Cash',
                        width: '40',
                        numeric: true
                },
                {
                        field: `totalCashIncome`,
                        label: 'Cash Income',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'totalCashExpense',
                        label: 'Cash Expense',
                        width: '40',
                        numeric: true
                },
                {
                        field: `cashActualOnhand`,
                        label: 'Cash Onhand',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'cashOverShort',
                        label: 'Over/Short',
                        width: '40',
                        numeric: true
                },                                    
            ]
        }
    },

    methods: {

        fetchShiftDetails() {
            if (UserStore.getters.getActiveStore) {
                this.dailyShifts = []
                this.isLoading = true
                this.dailyGroup = []
                this.resetTotals()
                var startDate = this.salesDateRange[0].toISOString().split('T')[0] 
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shifts/net?size=50"  + "&fromDate="+startDate+"&toDate="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.dailyShifts = response.data.data.sort(function(a,b){
                            return new Date(a.periodBeginDate) - new Date(b.periodBeginDate)
                            })
                        this.groupedByDate()
                        this.calculateTotals()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response) {
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                            if (err.response.status === 404 || err.response.status === 400) {
                                this.$buefy.toast.open({
                                    message: 'No shift data found',
                                    type: 'is-warning'
                                })
                            }
                        }

                        this.isLoading = false                     
                    })
                    
            }
        },

        fetchMonthlyReportItems() {
            this.monthlyReportItems = []

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/monthlyAssetReportItems"

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    
                    this.monthlyReportItems = response.data.data.filter(item => {
                        return item.name == 'Cash On Hand' || item.name == 'Cash In Safe'
                    })

                })
                .catch( (err) => {
                    this.monthlyReportItems = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                    if (err.response) {
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No data found',
                                type: 'is-warning'
                            })
                        }
                    }
                })
        }, 

        fetchMonthlyReport() {
            var lastDayPrevMonth = moment(this.salesDateRange[0]).add(-1, 'M').endOf('month').format("YYYY-MM-DD")
            this.monthlyReport = []
            this.prevMonthBalance = 0

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/monthlyAssetReport/" + lastDayPrevMonth

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    
                    var temp = response.data.data                    

                    if (temp.monthlyAssetReportDetailDTOList && temp.reportDate == lastDayPrevMonth) {
                        this.monthlyReportItems.forEach(item => {
                            // var reportItem = this.monthlyReportItems.find(o => o.id === item.monthlyAssetReportItemsId)
                            var reportItem = temp.monthlyAssetReportDetailDTOList.find(o => o.monthlyAssetReportItemsId === item.id)
                            if (reportItem) {
                                reportItem.name = item.name
                                reportItem.Balance = reportItem.multiplicand * reportItem.multiplier
                                this.prevMonthBalance += reportItem.Balance
                                this.monthlyReport.push(reportItem)
                            }
                        })
                    }
            

                    // console.log("Report",temp.reportDate, this.prevMonthBalance, this.monthlyReport )
                })
                .catch( (err) => {
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                    if (err.response) {
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No data found',
                                type: 'is-warning'
                            })
                        }
                    }                    
                })
        },

        groupedByDate() {

            var sortedDailyShifts = this.dailyShifts.sort((a,b) =>  b.periodBeginDate-a.periodBeginDate )

            sortedDailyShifts.forEach((item, index) => {
                item.shiftOrder = index
                if (isNaN(item.checkActualOnhand)) {
                    item.checkActualOnhand = 0
                }
                item.netLottery = this.netLottery(item)
                if (index > 0) {
                    item.prevCashOnHand = sortedDailyShifts[index-1].netCashOnHand
                } else {
                    item.prevCashOnHand = this.prevMonthBalance
                }
                item.cashOverShort = item.cashAmount -
                    item.cashRefundAmount +
                    item.netLottery +
                    item.totalCashIncome -
                    item.totalCashExpense -
                    item.safedropCashAmount -
                    item.cashActualOnhand -
                    item.checkActualOnhand + 
                    item.previousCashUsed

                item.netCashOnHand = item.prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm -
                                item.cashLoadedInAtm -
                                item.previousCashUsed
            })
            
            this.validSales =  sortedDailyShifts.filter(e => e.netSales !== null)
            this.dailyGroup = Object.values(this.validSales.reduce((a,{closingDate, ...props}) => {
                if(!a[closingDate]) {
                    a[closingDate]  = Object.assign({}, {closingDate: props,shifts : [props]});
                    a[closingDate].netSales = 0.00
                    a[closingDate].merchSales = 0.00
                    a[closingDate].fuelSales = 0.00
                    a[closingDate].cashAmount = 0.00
                    a[closingDate].totalCashIncome = 0.00
                    a[closingDate].totalCashExpense = 0.00
                    a[closingDate].cashActualOnhand = 0.00
                    a[closingDate].cashOverShort = 0.00
                    a[closingDate].bankDepositGeneral = 0.00
                    a[closingDate].bankDepositLottery = 0.00
                    a[closingDate].bankDepositAtm = 0.00
                    a[closingDate].cashLoadedInAtm = 0.00
                    a[closingDate].safedropAmount = 0.00
                    a[closingDate].safedropCashAmount = 0.00
                    a[closingDate].cashRefundAmount = 0.00
                    a[closingDate].netCashOnHand = 0.00
                    a[closingDate].prevCashOnHand = 0.00
                    a[closingDate].netLottery = 0.00
                    a[closingDate].lotteryNonCashSales = 0.00
                    a[closingDate].previousCashUsed = 0.00
                    a[closingDate].checkActualOnhand = 0.00
                    a[closingDate].instantLottoSales = 0.00
                    a[closingDate].instantLottoPayout = 0.00
                    a[closingDate].onlineLottoSales = 0.00
                    a[closingDate].onlineLotteryCanceled = 0.00
                    a[closingDate].onlineLottoPayout = 0.00
                    a[closingDate].lotteryMachineWithdraw = 0.00
                    // a[closingDate].salesDate = new Date(closingDate)
                    a[closingDate].salesDate = String(closingDate[1]).padStart(2, '0')+'/'+String(closingDate[2]).padStart(2, '0')+'/'+closingDate[0]
                } else {
                    if (props.netSales != null) {
                        a[closingDate].shifts.push(props);
                    }
                }
                this.tableNumericFields.forEach(function (element) {
                    if (props[element] === null) {
                        props[element] = 0.00
                    } else {
                        a[closingDate][element] += parseFloat(props[element])
                    }
                })

                return a;
            },{}));
            
            this.dailyGroup.forEach((item, index) => {
                item.shiftOrder = index
                if (index > 0) {
                    item.prevCashOnHand = this.dailyGroup[index-1].netCashOnHand
                } else {
                    item.prevCashOnHand = this.prevMonthBalance
                }

                item.netCashOnHand = item.prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm - 
                                item.cashLoadedInAtm -
                                item.previousCashUsed
            })

        },

        totalArrayColumn(array) {
            return array.reduce((a, b) => {
                return a + b.netSales;
            }, 0);
        },


        hidePrintPage() {
            this.shifts = {}
            this.isPrintPage = false
        },

        showPrintPage(row) {
            this.shiftsData = row
            this.isPrintPage = true
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        netOverShort(row) {
            return ((row.cashActualOnhand + 
                    row.safedropCashAmount) - 
                    (row.cashAmount - 
                    row.cashRefundAmount + 
                    row.totalCashIncome - 
                    row.totalCashExpense)).toFixed(2)            
        },

        previousBalanceAvail(row) {
            return row.netCashOnHand + row.bankDepositGeneral +
                row.bankDepositLottery + row.bankDepositAtm +
                row.cashLoadedInAtm - row.safedropCashAmount -
                row.cashActualOnhand + this.prevMonthBalance
        },

        setTotalCashRefundAmount() {
            this.totalCashRefundAmount = this.dailyGroup.reduce((accum,item) => accum + 
                this.getNumber(item.cashRefundAmount 
                ), 0)
        },

        setTotalCashAmount() {
            this.totalCashAmount = this.dailyGroup.reduce((accum,item) => accum + 
                this.getNumber(item.cashAmount  
                ), 0)
                this.setTotalCashIncExpAmount()
        },

        setTotalCashIncomeAmount() {
            this.totalCashIncomeAmount = this.dailyGroup.reduce((accum,item) => accum + 
                this.getNumber(item.totalCashIncome), 0)
        },
        
        setTotalCashExpenseAmount() {
            this.totalCashExpenseAmount = this.dailyGroup.reduce((accum,item) => accum + 
                this.getNumber(item.totalCashExpense), 0)
        },        

        setTotalCashIncExpAmount() {
            this.setTotalCashExpenseAmount()
            this.setTotalCashIncomeAmount()
            this.totalCashIncExpAmount = this.totalCashIncomeAmount - this.totalCashExpenseAmount
        },

        setTotalSafedropAmount() {
            this.totalSafedropAmount = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.safedropCashAmount ? item.safedropCashAmount : 0.00), 0)
        },

        setTotalPreviousCashUsed() {
            this.totalPreviousCashUsed = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.previousCashUsed ? item.previousCashUsed : 0.00), 0)            
        },

        setTotalCheckActualOnhand() {
            this.totalCheckActualOnhand = this.dailyGroup.reduce((accum,item) => accum + (item.checkActualOnhand ? item.checkActualOnhand : 0.00), 0)
        },

        setTotalNetLottery() {
            this.totalNetLottery = this.dailyGroup.reduce((accum,item) => accum + 
                this.getNumber(item.netLottery ? item.netLottery : 0.00)
            , 0)
            
        },

        setTotalCashActualOnhand() {
            this.totalCashActualOnhand = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.cashActualOnhand ? item.cashActualOnhand : 0.00), 0)
        },

        setTotalOverShort() {
            this.totalOverShort = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.cashOverShort ? item.cashOverShort : 0.00), 0)
        },

        setTotalDepositGeneral() {
            this.totalDepositGeneral = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.bankDepositGeneral ? item.bankDepositGeneral : 0.00), 0)
        },

        setTotalDepositLottery() {
            this.totalDepositLottery = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.bankDepositLottery ? item.bankDepositLottery : 0.00), 0)
        },

        setTotalDepositATM() {
            this.totalDepositATM = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.bankDepositAtm ? item.bankDepositAtm : 0.00), 0)
        },

        setTotalLoadedInAtm() {
            this.totalLoadedInAtm = this.dailyGroup.reduce((accum,item) => accum + this.getNumber(item.cashLoadedInAtm ? item.cashLoadedInAtm : 0.00), 0)
        },

        setTotalCashOverShort() {
            this.totalCashOverShort = (this.totalSafedropAmount + this.totalCashActualOnhand) - 
                (this.totalCashAmount - this.totalCashRefundAmount + this.totalNetLottery + 
                this.totalCashIncomeAmount - this.totalCashExpenseAmount) + this.totalCheckActualOnhand
        },

        setTotalCashOnhandThisMonth() {
            this.totalCashOnhandThisMonth = (this.totalCashActualOnhand + this.totalSafedropAmount + this.prevMonthBalance) - 
                this.totalDepositGeneral - this.totalDepositLottery - 
                this.totalDepositATM - this.totalLoadedInAtm
        },

        setTotalPeriodEndCashOnhand() {
            this.totalPeriodEndCashOnhand = this.dailyGroup[this.dailyGroup.length-1].netCashOnHand

            // this.totalPeriodEndCashOnhand = this.totalSafedropAmount + 
            //         this.totalCashActualOnhand -
            //         this.totalDepositGeneral -
            //         this.totalDepositLottery -
            //         this.totalDepositATM -
            //         this.totalLoadedInAtm
        },

        shiftNetCashOnHand(row) {
            
            return this.getNumber(row.cashActualOnhand ? row.cashActualOnhand : 0.00) +
                    this.getNumber(row.safedropCashAmount ? row.safedropCashAmount : 0.00) - 
                    this.getNumber(row.bankDepositGeneral ? row.bankDepositGeneral : 0.00) - 
                    this.getNumber(row.bankDepositLottery ? row.bankDepositLottery : 0.00) - 
                    this.getNumber(row.bankDepositAtm ? row.bankDepositAtm : 0.00) - 
                    this.getNumber(row.cashLoadedInAtm ? row.cashLoadedInAtm : 0.00)
        },

        netLottery(row) {

            return (this.getNumber(row.instantLottoSales ? row.instantLottoSales : 0.00) -
                this.getNumber(row.instantLottoPayout ? row.instantLottoPayout : 0.00) +
                this.getNumber(row.onlineLottoSales ? row.onlineLottoSales : 0.00) -
                this.getNumber(row.onlineLottoPayout ? row.onlineLottoPayout : 0.00) -
                this.getNumber(row.onlineLotteryCanceled ? row.onlineLotteryCanceled : 0.00) +
                this.getNumber(row.lotteryMachineWithdraw ? row.lotteryMachineWithdraw : 0.00) - 
                this.getNumber(row.lotteryNonCashSales ? row.lotteryNonCashSales : 0.00)                
                )
        },

        resetTotals() {
            this.totalCashAmount = 0
            this.totalCashRefundAmount = 0
            this.totalNetLottery = 0
            this.totalSafedropAmount = 0
            this.totalCashActualOnhand = 0
            this.totalOverShort = 0
            this.totalDepositGeneral = 0
            this.totalDepositLottery = 0
            this.totalDepositATM = 0
            this.totalLoadedInAtm = 0
            this.totalPeriodEndCashOnhand = 0
            this.totalCheckActualOnhand = 0
            this.totalCashIncomeAmount = 0
            this.totalCashExpenseAmount = 0
            this.totalCashIncExpAmount = 0
            this.totalCashOnhandThisMonth = 0
        },

        calculateTotals() {
            this.resetTotals()
            this.setTotalCashAmount()
            this.setTotalCashRefundAmount()
            this.setTotalNetLottery()
            this.setTotalSafedropAmount()
            this.setTotalCashActualOnhand()
            this.setTotalOverShort()
            this.setTotalDepositGeneral()
            this.setTotalDepositLottery()
            this.setTotalDepositATM()
            this.setTotalLoadedInAtm()
            this.setTotalCheckActualOnhand()
            this.setTotalPeriodEndCashOnhand()
            this.setTotalCashOverShort()
            this.setTotalCashOnhandThisMonth()
            this.setTotalPreviousCashUsed()
            this.salesChart = this.drawLeftChart()
        },

        drawLeftChart() {

            this.datasets = []
            var data = []
            var labels = []
            this.dailyGroup.forEach( day => {
                labels.push(day.closingDate.date)
            })
            this.chartItems.forEach( item => {
                
                data = []
                this.dailyGroup.forEach( day => {
                    data.push(day[item.field].toFixed(2))
                })

                this.datasets.push({
                    label: item.label,
                    data: data,
                    fill: false,
                    borderColor: item.color,
                    backgoundColor: item.color

                })                
            })
            this.leftChartData = {
                labels: labels,
                datasets: this.datasets,
                options: this.chartOptions
            }

        }, 

        async setReportDate(row) {
            this.shiftDate = moment(row.closingDate.date, 'MM-DD-YYYY').toDate()
        },

        async printDaysReport(row) {
            await this.setReportDate(row)
                .then(() => {
                    this.togglePDFDialog()
                })
            
        },
        
         async togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                await this.$refs.pdfReport.loadData()
            }
        },
        
        async togglePagePDFDialog() {
            this.isPagePDFDialog = !this.isPagePDFDialog
            if (this.isPagePDFDialog) {
                await this.$refs.pdfPageReport.loadData()
            }
        },

     },

    watch: {
        salesDateRange: async function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                await this.fetchMonthlyReportItems()
                await this.fetchMonthlyReport()
                await this.fetchShiftDetails()
            }
        }       

    },
    
    mounted() {

        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]      
    }    
}
</script>

<style>
.detailTable {
    background-color: rebeccapurple;
}

.nowrap {
    white-space: nowrap;
}

div.fit-container {
    /* width: min(100%,1000px); */
    /* max-width: inherit !important; */
    /* max-width: min(calc(100% - 50px), 1000px); */
    width: calc(100% - 70px);
    /* padding-right: 20px !important ; */
    /* overflow: auto; */
}

/* .th-wrap {
    white-space:initial;
} */

/* div.contain-scroll { */
    /* height: 200px; */
    /* overflow:initial !important; */
    /* position: sticky  !important; */
    /* top: 100px; */
    /* max-height:min-content; */
    /* width: min(100%,1000px); */
    /* height: 700px; */
    /* width: calc(100% - 75px); */
    /* overflow: auto; */
    /* overscroll-behavior-block: contain; */
    /* overflow-y: hidden; */
    /* max-height: 100vh; */
/* } */
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.veecli-form-entry {
    border-bottom: 0.5px solid gray;
    padding-bottom: 4.25em;
    height: 2em;
}
.veecli-form-entry-with-label {
    /* border-bottom: 0.5px solid gray; */
    padding-bottom: 0em;
    /* height: 2em; */
}
.veecli-table-heading {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2d7;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
}
.veecli-table-subhead {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.veecli-table-footer {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2;
    font-weight: bold;
    vertical-align: middle;
}

hr.totaline {
    margin: 0px;
    padding: 0px;
    border: 1px solid rgb(57, 56, 56);
}

/* html {
    overflow: hidden;
    height: 100%;
} */

/* body {
    height: calc(100% - 200px);
    overflow: auto;
} */
</style>