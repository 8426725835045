<template>
    <div class=" has-text-weight-semibold">
        <b-loading v-model="isLoading" >
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>                
        </b-loading> 
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">SHIFT SUMMARY CALENDAR VIEW</div>
                <div class="level-right has-text-centered">
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="printDaysReport('all')">Print</b-button> -->
                    &nbsp;&nbsp;
                    <b-button title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="generatePDF">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>                    
            </div>
        </div>       

        <div class="columns">
            <div class="column is-8 is-mobile">
                <div class="has-text-centered is-size-4">{{ monthName }} {{ month.getFullYear() }} - {{ showItem }}</div>
                <line-chart :chart-data="leftChartData" :options="chartOptions"></line-chart>
            </div>
            <div class="column">
                <!-- <p class="level-item has-text-centered"> -->
                    <b-datepicker
                        type="month"
                        v-model="month"
                        placeholder="Click to select..."
                        icon="calendar-today"
                        inline
                        :min-date = "minDate"
                        :max-date = "maxDate"
                        @input="fetchShiftDetails"
                        trap-focus>
                    </b-datepicker>
                    <!-- <currency-label :amount="0"></currency-label> -->
                <hr>
                <b-switch 
                    true-value="Net Sales"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem" 
                    type="is-success">
                        Net Sales
                </b-switch>
                <b-switch 
                    true-value="Cash Flow"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Cash Flow
                </b-switch> 
                <b-switch 
                    true-value="Inside Sales"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Inside Sales
                </b-switch>
                <b-switch 
                    true-value="Merchandise Sales"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Merchandise Sales
                </b-switch>                
                <b-switch 
                    true-value="Fuel Sales"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Fuel Sales
                </b-switch>
                <!-- <b-switch 
                    true-value="Fuel Volume"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Fuel Volume
                </b-switch> -->
                <b-switch 
                    true-value="Payout"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Payout & Safe
                </b-switch>
                <b-switch 
                    true-value="Cash Sales"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Cash Sales
                </b-switch>
                <b-switch 
                    true-value="Safe Drop"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Safe Drop
                </b-switch>                                                                    
                <b-switch 
                    true-value="Over/Short"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Over/Short
                </b-switch> 
                <!-- <br>
                <b-switch 
                    true-value="Deposit"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Deposit
                </b-switch>
                <b-switch 
                    true-value="Lottery Deposit"
                    size="is-small"
                    v-model="showItem"
                    @input="updateShowItem"
                    type="is-success">
                        Lottery Deposit
                </b-switch>                   -->
            </div>
        </div>

        <hr>

        <div id="ReportContent">
            <div class="columns" v-if="showStoreName">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <div class="column is-size-2 is-12">{{ getActiveStore.storeName }}</div>
                </div>
                </div>
            </div>            

            <div class="columns has-text-centered has-text-weight-bold">
                <div class="column is-size-2 is-12">{{ showItem }}</div>
            </div>
            <div class="columns has-text-centered has-text-weight-bold">
                <div class="column is-size-2 is-12">{{ monthName }} {{ month.getFullYear() }}</div>
            </div>
            <div class="panel">
                <div class="panel-heading has-text-weight-bold">
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 1 Total</p>
                            <p class="is-size-4"> {{ shift1Total | formatCurrency }}</p>
                            </div>
                        </div>
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 2 Total</p>
                            <p class="is-size-4"> {{ shift2Total | formatCurrency }}</p>
                            </div>
                        </div>                    
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 3 Total</p>
                            <p class="is-size-4"> {{ shift3Total | formatCurrency }}</p>
                            </div>
                        </div>
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Month Total</p>
                            <p class="is-size-4">{{ monthTotal | formatCurrency }}</p>
                            </div>
                        </div>
                    </div>                                                                                  
                </div>
            </div>        

            <div class="day-of-week has-text-centered has-text-weight-bold">
                <div class=""></div>
                <div class="">Sunday</div>
                <div class="">Monday</div>
                <div class="">Tuesday</div>
                <div class="">Wednesday</div>
                <div class="">Thursday</div>
                <div class="">Friday</div>
                <div class="">Saturday</div>
            </div>
            <div class="date-grid has-text-centered has-text-weight-bold" id="data-gid">
            </div>
            <div class="is-size-7">*** Only 3 shifts shown on calendar view</div>
            <!-- Number of Shifts: {{ this.shiftCount }} -->

            <hr>
            <div class="panel">
                <div class="panel-heading has-text-weight-bold">
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 1 Total</p>
                            <p class="is-size-4"> {{ shift1Total | formatCurrency }}</p>
                            </div>
                        </div>
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 2 Total</p>
                            <p class="is-size-4"> {{ shift2Total | formatCurrency }}</p>
                            </div>
                        </div>                    
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Shift 3 Total</p>
                            <p class="is-size-4"> {{ shift3Total | formatCurrency }}</p>
                            </div>
                        </div>
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Month Total</p>
                            <p class="is-size-4">{{ monthTotal | formatCurrency }}</p>
                            </div>
                        </div>
                    </div>                                                                                  
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment'
import UserStore from "../../../store/UserStore"
import axios from 'axios'
import LineChart from '../dashboard/LineChart'
import jspdf from "jspdf"

export default {
    components: {
        LineChart
    },

    metaInfo: {
        title: 'Shift Calendar View'
    },

    data() {
        return {
            showStoreName: false,
            isLoading: true,
            showItem: "Net Sales",
            fieldToShow: "netSales",
            appCalendar: {},
            daysOfMonth: [],
            shiftsArray: [],
            shift1Total: 0.00,
            shift2Total: 0.00,
            shift3Total: 0.00,
            shiftCount: 0,
            monthTotal: 0.00,
            dateRange: [new Date(), new Date()],
            user: [],
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            month: new Date(),
            chartOptions: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                },
                responsive: true,
                maintainAspectRatio: false
            },
            leftChartData: {},
            datasets: [],
            yaxislabels: [],
            shifts: ['Shift 1', 'Shift 2', 'Shift 3'],
            isFullPage: true,
        }
    },

    methods: {

        fetchShiftDetails() {
            if (UserStore.getters.getActiveStore) {
                this.isLoading = true
                this.shiftCount = 0
                // const loadingComponent = this.$buefy.loading.open({
                //     container: this.isFullPage ? null : this.$refs.element.$el
                // })                

                this.startDate = (new Date(this.month.getFullYear(), this.month.getMonth(), 1)).toISOString().split('T')[0]                    
                this.endDate = (new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0)).toISOString().split('T')[0]

                // var startDate = moment(this.startDate).format('YYYY-MM-DD')
                // var endDate = moment(this.endDate).format('YYYY-MM-DD')

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shifts/net?size=50"  + "&fromDate="+this.startDate+"&toDate="+this.endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.dailyShifts = response.data.data.sort(function(a,b){
                            return new Date(a.periodBeginDate) - new Date(b.periodBeginDate)
                            })

                        this.shiftsArray = []
                        this.daysOfMonth = []
                        this.appCalendar.innerHTML = ''
                        this.datasets = []

                        this.dailyShifts.forEach( (shift) => {
                            let shiftdate = moment(shift.date,'MM-DD-YYYY')
                            let day = shiftdate.format('D')
                            if (this.shiftsArray[day] == undefined) {

                                this.shiftsArray[day] = {"shifts": []}
                            } 
                            this.shiftsArray[day].shifts.push({
                                "id": shift.id,
                                "date": shiftdate, 
                                "periodBeginDate": shift.periodBeginDate,
                                "periodEndDate": shift.periodEndDate,
                                "netSales": shift.netSales, 
                                "insideSales": shift.insideSales,
                                "merchSales": shift.merchSales,
                                "fuelSales": shift.fuelSales,
                                "cashFlow": shift.cashFlow,
                                "totalSafeDrop": shift.totalSafeDrop,
                                "totalPaymentOut": shift.totalPaymentOut,
                                "cashAmount": shift.cashAmount,
                                "cashActualOnhand": shift.cashActualOnhand,
                                "cashOverShort": shift.cashOverShort,
                                "employeeName": shift.employeeName
                                })
                            if (this.shiftCount < this.shiftsArray[day].shifts.length) {
                                this.shiftCount = this.shiftsArray[day].shifts.length
                            }
                        })
                        // console.log("JSON",JSON.stringify(this.shiftsArray))
                        this.updateCalendar()
                        this.isLoading = false
                        // loadingComponent.close()
 
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No shift data found',
                                type: 'is-warning'
                            })
                        }
                        this.noDataAvailable()
                        this.isLoading = false
                        // loadingComponent.close()                       
                    })
            }
        },

        updateCalendar() {
            this.getDaysArrayByMonth()
            this.drawLeftChart()
            this.makeCalendar()

        },

        noDataAvailable() {
            this.shiftsArray = []
            this.daysOfMonth = []
            this.appCalendar.innerHTML = 'No Data Found' 
            this.leftChartData = {}
            this.datasets = []
            this.shift1Total = 0.00
            this.shift2Total = 0.00
            this.shift3Total = 0.00
            this.monthTotal = 0.00
            this.drawLeftChart()
        },

        getDaysArrayByMonth() {

            this.shift1Total = 0.00
            this.shift2Total = 0.00
            this.shift3Total = 0.00
            this.monthTotal = 0.00

            var daysInMonth = moment(this.month).daysInMonth()

            // if (this.month < new Date()) {
            //     daysInMonth = new Date().getDate()
            // }
            
            while(daysInMonth) {
                var current = moment(this.month).date(daysInMonth)
                this.daysOfMonth.push(current)
                daysInMonth--
                // if (daysInMonth == 0) break
            }
            this.daysOfMonth.sort((a, b) => a - b)
        },

        getFirstDayOfMonth() {
            let day = parseInt(this.daysOfMonth[0].format('d'))+1
            return day
        },

        getLastDayOfMonth() {
            let day = parseInt(this.daysOfMonth[this.daysOfMonth.length-1].format('d'))+1
            return day
        },

        makeCalendar() {


            var firstDay = this.getFirstDayOfMonth()

            var daysInMonth = moment(this.month).daysInMonth()

            this.insertColLabel()
            for (var n=1; n<firstDay; n++) {
                this.insertEmptyCell()
            }
            let i = firstDay
            this.daysOfMonth.forEach((day) => {
                    this.insertDay(day)
                    if (i% 7 === 0) {
                        if (daysInMonth > i || this.getLastDayOfMonth() == 1) {
                            this.insertColLabel()                    
                        }              
                    }
                    i++
            })

            let j= i% 7
            if (j > 1) {
                while (j<8) {
                    this.insertEmptyCell()
                    j++
                }
            }

        },

        insertColLabel() {
            let data = "<div class='sales-data shift-label-col'>"
            data = data + "<div class='bottom-bordered-row'>Shift 1</div>"
            data = data + "<div class='bottom-bordered-row'>Shift 2</div>"
            data = data + "<div class='bottom-bordered-row'>Shift 3</div>"
            data = data + "<div class='bottom-bordered-row'>Total</div>"
            data = data + "</div>"
            // this.appCalendar.insertAdjacentHTML("beforeend",`<div class="box data-cell"><br>${data} </div>`)
            this.appCalendar.insertAdjacentHTML("beforeend",`<div class='box data-cell row-heading'><div class='is-size-4'>&nbsp;</div> ${data}</div>`)


        },

        insertEmptyCell() {
            this.appCalendar.insertAdjacentHTML("beforeend",`<div class="box data-cell"> </div>`)
        },

        insertDay(day) {

            const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                    })

            let shift1, shift2, shift3, dayTotal = 0.00
            let dayInt = parseInt(day.format('D'))

            if (this.shiftsArray[dayInt] === undefined) {
                this.shiftsArray[dayInt] = {"shifts": [ {
                                "date": day, 
                                "netSales": 0.00, 
                                "insideSales": 0.00,
                                "merchSales": 0.00,
                                "fuelSales": 0.00,
                                "totalPaymentOut": 0.00,
                                "cashAmount": 0.00,
                                "cashFlow": 0.00,
                                "totalSafeDrop": 0.0,
                                "cashActualOnhand": 0.00,
                                "cashOverShort": 0.00,
                                "employeeName": 0.00,
                                "bankDepositLottery": 0.00,
                                "bankDepositGeneral": 0.00
                                }]}
            }

            if (this.fieldToShow === "employeeName") {
                shift1 = this.shiftsArray[dayInt].shifts[0].employeeName 
                shift2 = this.shiftsArray[dayInt].shifts[1].employeeName
                shift3 = this.shiftsArray[dayInt].shifts[2].employeeName
            } else {

                if (this.shiftsArray[dayInt].shifts[0] === undefined) {
                    shift1 = 0.00
                } else {
                    if (this.shiftsArray[dayInt].shifts[0][this.fieldToShow] === null) {
                        shift1 = 0.00
                    } else {
                        shift1 = parseFloat(this.shiftsArray[dayInt].shifts[0][this.fieldToShow])
                    }                 
                }

                if (this.shiftsArray[dayInt].shifts[1] === undefined) {
                    shift2 = 0.00
                } else {
                    if (this.shiftsArray[dayInt].shifts[1][this.fieldToShow] === null) {
                        shift2 = 0.00
                    } else {
                        shift2 = parseFloat(this.shiftsArray[dayInt].shifts[1][this.fieldToShow])
                    }                 
                }

                if (this.shiftsArray[dayInt].shifts[2] === undefined) {
                    shift3 = 0.00
                } else {
                    if (this.shiftsArray[dayInt].shifts[2][this.fieldToShow] === null) {
                        shift3 = 0.00
                    } else {
                        shift3 = parseFloat(this.shiftsArray[dayInt].shifts[2][this.fieldToShow])
                    }                
                }

                dayTotal=formatter.format(shift1 + shift2 + shift3)
                this.shift1Total = this.shift1Total + shift1
                this.shift2Total = this.shift2Total + shift2
                this.shift3Total = this.shift3Total + shift3
                this.monthTotal = this.monthTotal + shift1 + shift2 + shift3

                shift1=formatter.format(shift1)
                shift2=formatter.format(shift2)
                shift3=formatter.format(shift3)
            }
            
            // console.log("Day Total",day.format('D'), dayTotal)
            let data = `<div class='sales-data'>`
            data = data + `<div class='columns bottom-bordered-row'><div class='column has-text-right'>${shift1}</div></div>`
            data = data + `<div class='columns bottom-bordered-row'><div class='column has-text-right'>${shift2}</div></div>`
            data = data + `<div class='columns bottom-bordered-row'><div class='column has-text-right'>${shift3}</div></div>`
            data = data + `<div class='columns bottom-bordered-row'><div class='column has-text-right'>${dayTotal}</div></div>`
            data = data + `</div>`

            if (day.format('dddd') === "Sunday" || day.format('dddd') === "Saturday" ) {
                this.appCalendar.insertAdjacentHTML("beforeend",`<div class='box data-cell weekend'><div class='is-size-3'>${day.format('D')}</div> ${data}</div>`)
            } else {
                this.appCalendar.insertAdjacentHTML("beforeend",`<div class='box data-cell'><div class='is-size-3'>${day.format('D')}</div> ${data}</div>`)
            }            
        },

        drawLeftChart() {

            this.datasets = []
            var data = []
            var labels = []

            var i 
            for (i = 0; i < this.shifts.length; i++) {
                
                this.shiftsArray.forEach( day => {
                    
                    if (day.shifts[i] === undefined) {
                        data.push(0.00)
                    } else {
                        data.push(day.shifts[i][this.fieldToShow])                            
                    }

                })

                this.datasets.push({
                    label: this.shifts[i],
                    data: data,
                    fill: false,
                    borderColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                    backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),

                })
                data = []

            }

            this.shiftsArray.forEach(day => {
                labels.push(day.shifts[0].date.format('MM/DD/YYYY'))
            })
            this.leftChartData = {
                labels: labels,
                datasets: this.datasets,
                options: this.chartOptions
            }
        },

        updateShowItem() {
            // this.shiftsArray = []
            this.daysOfMonth = []
            this.appCalendar.innerHTML = ''

            if (!this.showItem) {
                this.showItem = "Net Sales"
            }

            switch (this.showItem) {
                case "Inside Sales":
                    this.fieldToShow = "insideSales"
                    break;
                case "Merchandise Sales":
                    this.fieldToShow = "merchSales"
                    break;
                case "Fuel Sales":
                    this.fieldToShow = "fuelSales"
                    break;
                case "Payout":
                    this.fieldToShow = "totalPaymentOut"
                    break;
                case "Cash Sales":
                    this.fieldToShow = "cashAmount"
                    break;
                case "Actual Cash":
                    this.fieldToShow = "cashActualOnhand"
                    break;
                case "Safe Drop":
                    this.fieldToShow = "totalSafeDrop"
                    break;                                        
                case "Cash Flow":
                    this.fieldToShow = "cashFlow"
                    break;
                case "Over/Short":
                    this.fieldToShow = "cashOverShort"
                    break;
                case "Employee":
                    this.fieldToShow = "employeeName"
                    break;
                case "Deposit":
                    this.fieldToShow = "bankDepositGeneral"
                    break;
                case "Lottery Deposit":
                    this.fieldToShow = "bankDepositLottery"
                    break;
                default:
                    this.fieldToShow = "netSales"
                    break;
            }                    

            this.updateCalendar()
        },

        toggleShowStoreName() {
            if (this.showStoreName) {
                this.showStoreName = false
            } else {
                this.showStoreName = true
            }
        },

        generatePDF() {

            this.toggleShowStoreName()

            const html = document.querySelector("#ReportContent")
            let srcwidth = html.scrollWidth

            const doc = new jspdf('p','pt','a4')

            doc.html(html, {
                html2canvas: {
                    scale: 595.26 /srcwidth,
                    scrollY: 0
                },
                filename: 'pandlreport',
                x: 0,
                y: 10,
                callback: function(pdf) {
                    window.open(pdf.output("bloburl"))
                }
            })

            this.sleep(2000).then(() => {
                this.toggleShowStoreName()
            })
        },
        
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }          

    },

    computed: {
        monthName() {
            return moment(this.month).format('MMMM')
        },
        getActiveStore() {

            return UserStore.getters.getActiveStore
        }, 

    },

    mounted() {
        this.user = UserStore.getters.getUser
        this.appCalendar = document.querySelector("#data-gid")

        // this.updateCalendar()
        this.fetchShiftDetails()
    }
}
</script>


<style>
:root {
    --first-column: '1';
}

.day-of-week,
.date-grid {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(8, 1fr);
}

.date-grid {
    height: 100%;
    background-color: rgb(235, 232, 232);
}

.data-cell {
    height: 100%;
}

.date-grid div:first-child {
    grid-column: var(--first-column);
}

.day-of-week {
    height: 40px;
    background-color:gray;
    vertical-align: middle;
    color: white;
    padding-top: 8px;
}

.weekend {
    background-color: rgb(255, 255, 233);
}

.sales-data {
    color: rgb(0, 0, 0);
    padding-top: 30px;
}

.row-heading {
    color: rgb(8, 8, 8);
    background-color: rgb(226, 225, 225);
    padding-top: 30px;
}

.bottom-bordered-row {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.shift-label-col {
    width: 100%;
}

#ReportContent {
    margin-top: 5em;
}
</style>