<template>
<div class="panel">

        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">

                <div class="level-left has-text-centered" @click="showExpenseTable=!showExpenseTable">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-plus-square has-text-primary" v-if="!showExpenseTable && !isPrinting"></i>
                    <i class="fas fa-minus-square has-text-danger" v-if="showExpenseTable && !isPrinting"></i>
                </div>
                <div class="column is-size-6 has-text-centered"> INVENTORY PURCHASE / EXPENSES </div>
                    <div class="level-right has-text-centered">
                        <div class="button is-primary has-text-white is-small" @click="doExpenseAction('add',index)" v-if="allowEdit && !isPrinting">
                            <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div> 
                </div>
        </div>
        <div class="panel-block">

        <b-table class="control" v-if="shift.expenses && (showExpenseTable || isPrinting)"
            :visible="!isLoading"
            :striped="isStriped"
            :data='shift.expenses'
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="dueOn"
            default-sort-direction="desc"
            :show-detail-icon="showDetailIcon && !isPrinting"
            detailed
            detail-key="id"            
            >

            <template>

                <b-table-column class="is-2" field="vendorName" label="Vendor/Employee" v-slot="props" sortable sorticon>
                {{ props.row.vendorOrEmployee }}
                </b-table-column>

                <b-table-column class="is-2" field="description" label="Type" v-slot="props" sortable sorticon>
                <span v-if="props.row.expenseTypeId == 2">{{ getDeptMappingName(props.row.deptMapping) }}</span> 
                <span v-else>{{ props.row.expenseTypeName }}</span>
                </b-table-column>

                <b-table-column class="is-2" field="description" label="Desc" v-slot="props" sortable sorticon>
                {{ props.row.description }}
                </b-table-column>
                
                <b-table-column numeric class="is-2 has-text-right" field="amount" label="Amount" v-slot="props" sortable sorticon>
                {{ props.row.amount | formatCurrency }}
                </b-table-column>

                <b-table-column class="is-2" field="method" label="Method" v-slot="props" sortable sorticon>
                <!-- {{ totalPayments(props.row) | formatCurrency }} -->
                {{ props.row.payments[0].method }}-{{ props.row.payments[0].status }}
                </b-table-column>

                <b-table-column class="is-2" field="dueOn" label="Paid/Due" v-slot="props" sortable sorticon>
                {{ props.row.payments[0].expectedClearingDate |formatDate }}
                </b-table-column>                                    

                <b-table-column class="is-2" v-slot:default="props" label="" sortable sorticon v-if="allowEdit && !isPrinting">
                <!-- <div class="button has-text-danger is-small" @click="doExpenseAction('delete',index, props.row)">
                    <i class="fas fa-trash-alt"></i>
                </div>
                &nbsp;
                <div class="button has-text-info is-small is-outlined rounded" @click="doExpenseAction('edit',index, props.row)">
                    <i class="fas fa-edit"></i>
                </div> -->
                    <b-button  class="is-danger is-small" title="Delete" 
                        v-on:click="doExpenseAction('delete',index, props.row)" outlined rounded>
                        <i class="fas fa-trash-alt"></i>
                    </b-button>
                    &nbsp;
                    <b-button class="is-primary is-small" title="Edit"  
                        v-on:click="doExpenseAction('edit',index, props.row)" outlined rounded>
                        <i class="far fa-edit"></i>
                    </b-button>

                </b-table-column>                                  

            </template>

            <template slot="detail" slot-scope="props">

                <div class="columns is-fullwidth is-mobile veecli-table" v-for="payment in props.row.payments" :key="payment.id">
                    <div class="column is-1">
                        {{ payment.dueOn}}
                    </div>
                    <div class="column is-1">
                        {{ payment.method}}
                    </div>
                    <div class="column is-1">
                        {{ payment.referenceNumber}}
                    </div>
                    <div class="column is-1">
                        {{ payment.status}}
                    </div>
                    <div class="column is-2">
                        {{ payment.clearedOn | formatDate}}
                    </div>                    
                    <div class="column is-4">
                        {{ payment.userName}}
                    </div>
                    <div class="column is-2">
                        {{ payment.amount | formatCurrency}}
                    </div>

                </div>

            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"
                    message="No Expense for this shift"> 
                </data-loading>
            </template>

        </b-table>

        </div>

        <div class="panel-heading has-text-weight-bold">
            <div class="columns">
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Posted<br>Total Expense</p>
                    <p class="is-size-6"> {{ totalExpense | formatCurrency }}</p>
                    </div>
                </div>
                <!-- <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total <br>Payments</p>
                    <p class="is-size-6"> {{ grantTotalPayments | formatCurrency }}</p>
                    </div>
                </div>                     -->
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Posted<br>Non-cash Expense</p>
                    <p class="is-size-6"> {{ nonCashPayments | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Register<br>Non-cash Payout</p>
                    <p class="is-size-6"> {{ shift.totalPaymentOut - shift.totalSafeDrop - shift.payOut | formatCurrency }}</p>
                    </div>
                </div>                 
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Posted<br>Cash Expense</p>
                    <p class="is-size-6">{{ cashPayments | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Register<br>Cash Payout</p>
                    <p class="is-size-6"> {{ shift.payOut | formatCurrency }}</p>
                    </div>
                </div>                               
            </div>                                                                                  
        </div>

        <shift-expense-form
            :shiftNum="shiftNum"
            :shift="shift"
            :expense="expenseTemp"
            :vendors="vendors"
            :employees="employees"
            :expenseTypes="expenseTypes"
            :expenseBefore="expenseBefore"
            :date="date"
            :action="action"
            :isShowDialog="isShowExpenseDialog"  
            @hide-dialog="toggleExpenseDialog"
            @save-record="saveExpenseRecord"
            >
        </shift-expense-form>
    </div>

</template>


<script>

import ShiftExpenseForm from './ShiftExpenseForm'
import DataLoading from '../../../../components/app/DataLoading'
import UserStore from "../../../../store/UserStore"

export default {

    components: {
        ShiftExpenseForm,
        DataLoading
    },

    props: {
        shift: {},
        index: Number,
        vendors: [],
        employees: [],
        expenseTypes: [],
        date: null,
        user: null,
        allowEdit: Boolean,
        isPrinting: Boolean
    },

    data() {
        return {
            isShowExpenseDialog: false,
            showExpenseTable: false,
            expense: {},
            shiftNum: 0,
            action: "add",
            isLoading: false,
            isStriped: true,
            isScrollable: false,
            isPaginated: false,
            perPage: 20,
            showDetailIcon: true,
            isFullPage: true,
            expenseTemp: {
                "id": 0,
                "shiftId": 0,
                "date": this.shift.date,
                "dueOn": this.shift.date,
                "expenses": this.shift.expenses,
                "description": "",
                "amount": 0.00,
                "expenseTypeId": 0,
                "employeePayrollHours": 0.00,
                "fuelVolume": 0.000,
                "creditTerm": "NET0",
                "vendor": {"id": 0, "name": ""},
                "referenceNumber": "",
                "row": -1,
                "payments": [{
                    "id": 0,
                    "amount": 0.00,
                    "method": "Cash",
                    "referenceNumber": null,
                    "status": "Paid",
                    "clearedOn": this.shiftDate,
                    "paymentDate": this.shiftDate,

                }]
            },
            expenseBefore: {},
            storeMappings: UserStore.getters.getStoreMappings,
        }
    },

    methods: {

        doExpenseAction(action, shiftIndex, expense) {

            this.action = action
            this.storeId = this.user.activeStore
            this.shiftNum = this.shiftNumber(shiftIndex)

            switch(this.action) {
                case 'add':
                    this.toggleExpenseDialog()

                    // console.log("Shift Date", this.date.toISOString().substring(0,10))

                    this.expenseTemp = {
                        "id": null,
                        "salesId": this.shift.id,
                        "date": this.date.toISOString().substring(0,10),
                        "dueOn": this.date,
                        // "dueOnObj": this.date,
                        "description": "",
                        "amount": 0.00,
                        "expenseTypeId": 0,
                        "expenseTypeName": "",
                        "creditTerm": "NET0",
                        "vendorId": 0,
                        "employeeId": 0,
                        "employeePayrollHours": 0.00,
                        "fuelVolume": 0.000,
                        "deptMapping": "",
                        "referenceNumber": "",
                        "row": -1,
                        "userId": this.user.id,
                        "payments": [{
                            "id": null,
                            "amount": 0.00,
                            "userId": this.user.id,
                            "method": "Cash",
                            "referenceNumber": null,
                            "status": "Paid",
                            "clearedOn": null,
                            "paymentDate": this.date.toISOString().substring(0,10),
                            "expectedClearingDateObj": null,

                        }]
                    }
                    break

                case 'edit':
                    if (expense.expenseTypeId == 25 && this.shift.creditCardFees > 0.00) {
                        this.$buefy.toast.open({
                            message: "Please use Credit/Debit Charges option from left menu",
                                    type: 'is-danger'
                        }) 
                        return
                    }
                    if (expense.payments[0].method != "Cash" && expense.payments[0].status != "Open") {
                        this.$buefy.toast.open({
                            message: "Payment status is " + expense.payments[0].status + ". Not allowed to modify expense.",
                                    type: 'is-danger'
                        }) 
                        return
                    }
                    this.expenseBefore = JSON.parse(JSON.stringify(expense))
                    this.expenseTemp = expense
                    this.expenseTemp.row = this.shift.expenses.findIndex((element) => element == expense)
                    this.expenseTemp.dueOn = expense.dueOn
                   // this.expenseTemp.dueOnObj = new Date(expense.dueOn)
                    this.expenseTemp.expenseTypeName = ""
                    if (this.expenseTemp.payments.length == 0) {
                        console.log("Expense has no payments")
                        this.expenseTemp.payments =  [{
                            "id": null,
                            "amount": 0.00,
                            "userId": this.user.id,
                            "method": "Credit",
                            "referenceNumber": null,
                            "status": "Not Paid",
                            "clearedOn": null,
                            "paymentDate": this.date.toISOString().substring(0,10),
                            "expectedClearingDateObj": null, 
                        }]
                    } else {
                        if (this.expenseTemp.payments[0].expectedClearingDate != null) {
                            // console.log("Original", this.expenseTemp.payments[0].expectedClearingDate)
                            let tempDate = this.expenseTemp.payments[0].expectedClearingDate
                            let tempYear = tempDate.substring(0, 4)
                            let tempMonth = tempDate.substring(5,7)
                            let tempDay = tempDate.substring(8,10)

                            let dt2 = tempYear + "/" + tempMonth + "/" + tempDay
                            // console.log("My Date", dt2)
                            this.expenseTemp.payments[0].expectedClearingDateObj =  new Date(dt2)
                        } else  {
                            this.expenseTemp.payments[0].expectedClearingDateObj = null
                        }
                        
                    }
                    this.toggleExpenseDialog()
                    break

                case 'delete':
                    if (expense.payments[0].method != "Cash" && expense.payments[0].status != "Open") {
                        this.$buefy.toast.open({
                            message: "Payment status is " + expense.payments[0].status + ". Not allowed to delete expense.",
                                    type: 'is-danger'
                        }) 
                        return
                    }          
                    if (process.env.VUE_APP_DEBUG == 'true')  console.log("Deleting", expense)
                    this.$buefy.dialog.confirm({
                        title: 'Deleting Expense',
                        message: 'Are you sure you want to <b>delete</b> expense from <b>' + expense.vendorName + '</b> for <b>' + parseFloat(expense.amount).toLocaleString('en-US', { style: 'currency',  currency: 'USD',}) + '</b>?',
                        confirmText: 'Delete Expense',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            var row = this.shift.expenses.findIndex((element) => element == expense)
                            this.shift.expenses.splice(row, 1)
                            this.$buefy.toast.open('Expense from ' + expense.vendorName +' deleted!')
                            this.$emit("save",this.index)
                        }
                    })
                    break
            }
            this.isLoading = false

        },

        getDeptMappingName(deptMapping) {
            var deptMap = this.storeMappings.find(item => item.value === deptMapping)
            if (deptMap) {
                return deptMap.name
            }
            return ""
        },

        shiftNumber(index) {
            return index + 1
        },        

        toggleExpenseDialog(shiftid) {
            this.shiftId = shiftid
            this.isShowExpenseDialog = ! this.isShowExpenseDialog
        },


        saveExpenseRecord() {
            
            this.isLoading = true
            if (process.env.VUE_APP_DEBUG == 'true')  console.log("Saving Expense Record", this.expenseTemp)

            if (this.expenseTemp.row == -1) {
                this.expenseTemp.row = null
                this.shift.expenses.push(this.expenseTemp)
            }

            if (this.expenseTemp.row > -1) {
                this.expenseTemp.row = null
            }
            // console.log("Save", this.expenseTemp)
            this.isLoading = false
            this.$emit("save",this.index)
        },

        updateExpenseRecord() {

            // console.log("Update Expense Record", this.expenseTemp.id)

        },

        addExpenseRecord() {

            this.expenseTemp.id = null
            this.expenseTemp.userId = 1
            if (this.expenseTemp.payments.length > 0) {
                this.expenseTemp.payments[0].userId = 1
            }

            this.shift.expenses.push(this.expenseTemp)

            if (process.env.VUE_APP_DEBUG == 'true') console.log("Add Expense Record")

        },

        totalPayments(row) {

            var paidExpenses = row.payments.filter((item => (item.method != "Credit")) )
            return paidExpenses.reduce((accum,item) => accum + parseFloat(item.amount), 0)

        },


        getNumber(inp) {
            // console.log("Data",inp)
            // inp = inp.replace(/\D/g,'')
            if (Number(inp)) {
                return parseFloat(inp)
            }
            return 0
        },        
    },

    computed: {

        cashPayments() {

            var filteredExpenses = this.shift.expenses.filter(item => (item.payments.length > 0))
            var total = 0.00
            filteredExpenses.forEach(expense => {
                var noncash = expense.payments.filter(item => (item.method == "Cash"))
                total = total + noncash.reduce((accum, item) => accum + parseFloat(item.amount), 0)
            })

            return total

        },

        nonCashPayments() {

            var filteredExpenses = this.shift.expenses.filter(item => (item.payments.length > 0))
            var total = 0.00
            filteredExpenses.forEach(expense => {
                var noncash = expense.payments.filter(item => (item.method != "Cash" && item.method != "Credit"))
                total = total + noncash.reduce((accum, item) => accum + parseFloat(item.amount), 0)
            })

            return total

        },

        totalExpense() {
            return this.shift.expenses.reduce((accum,item) => accum + parseFloat(item.amount), 0)
        },

        grantTotalPayments() {
            return this.nonCashPayments + this.cashPayments
        },

        shiftEditableExpense(row) {

            if (this.shift.creditCardFees > 0.00 && row.expenseType == 25) {
                return false
            }

            return true
        },
        
    },
    
}
</script>

<style>
.margin-7-all {
   margin: 7px;
}
</style>