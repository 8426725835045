<template>
<section>
    <div class="panel has-text-weight-semibold" >
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">CURRENT FUEL INVENTORY</div>
                <div class="level-right has-text-centered">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>
            </div>            
        </div>
        <hr>
        <b-table 
            :data='tankInventory.tankInventoryList'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=tankName
            defaultSortDirection='asc'
            >

            <template>

                <b-table-column field="tankName" label="Name" sortable sorticon v-slot="props">
                    {{ props.row.tankName }}
                </b-table-column>
                <b-table-column field="product" label="Product" sortable sorticon v-slot="props">
                    {{ props.row.product }}
                </b-table-column>
                <b-table-column field="tankAlarmStatus" label="Tank Status" sortable sorticon v-slot="props">
                    {{ props.row.tankAlarmStatus }}
                </b-table-column>

                <b-table-column numeric field="waterHeight" label="Water" sortable sorticon v-slot="props">
                    {{ props.row.waterHeight }} in.
                </b-table-column> 
                <b-table-column numeric field="temperature" label="Temp." sortable sorticon v-slot="props">
                    {{ props.row.temperature }}°
                </b-table-column>                 
                <b-table-column numeric field="totalVolumeOfTank" label="Capacity" sortable sorticon v-slot="props">
                    {{ props.row.totalVolumeOfTank }} gal.
                </b-table-column>                                                

                <b-table-column numeric field="ullage" label="Ullage" sortable sorticon v-slot="props">
                    {{ props.row.ullage }} gal.
                </b-table-column> 
                <b-table-column numeric field="volume" label="Volume" sortable sorticon v-slot="props">
                    <b-tooltip 
                        type="is-primary">
                        <template v-slot:content >
                            Last Updated: {{ props.row.lastModifiedDate | formatDateTime}} 
                        </template>
                        <span class="tooltip">  
                            {{ props.row.volume }} gal.
                        </span>
                    </b-tooltip>                    
                </b-table-column> 
                <b-table-column width="75" field="tankFuelVolumeStatus" label="Volume %" sortable sorticon v-slot="props">
                    <b-tooltip 
                        type="is-primary">
                        <template v-slot:content >
                            Minimum: {{ props.row.minimumVolumePercentage }}% Warning: {{ props.row.warningVolumePercentage }}%
                        </template>
                        <span class="tooltip">  
                            <!-- {{ props.row.tankFuelVolumeStatus }} -->

                                <b-progress 
                                    :rounded="false" 
                                    :value="props.row.volAvailablePercentage" 
                                    :type="progressType(props.row)" 
                                    show-value
                                ></b-progress>
                        </span>
                    </b-tooltip>
                    
                </b-table-column>                
                                                                
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>

        </div>

        <FuelCurrentInventoryPDF
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :tankInventory="tankInventory"
            @close-pdf-dialog="togglePDFDialog"
            >
        </FuelCurrentInventoryPDF>
    
</section>

</template>


<script>
import UserStore from "../../../store/UserStore"
import axios from 'axios'
import FuelCurrentInventoryPDF from "./FuelCurrentInventoryPDF";
import DataLoading from '../../../components/app/DataLoading'

export default {
    data() {
        return {
            isPaginated: false,
            perPage: 20,
            tankInventory: [],
            user: {},
            api_url: "",
            isShowPDFDialog: false,
            isLoading: true,
            isFullPage: false,
        }
    },

    components: {
        FuelCurrentInventoryPDF,
        DataLoading,
    },

    metaInfo: {
        title: 'Fuel Inventory'
    },    

    methods: {
        fetchTankInventory() {
            if (this.user.activeStore) {

                const url = this.api_url + 'stores/' + this.user.activeStore 
                            + '/tanks/status'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.data.data.tankInventoryList) {

                            this.tankInventory = response.data.data

                        } else {
                            this.tankInventory = {}
                            this.dataNotAvailable = true
                        }

                    })
                    .catch( (err) => {
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            this.isLoading = false
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }

                    })
            }

        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },
        
        progressType(row) {

            if (row.volAvailablePercentage < row.minimumVolumePercentage + 5) {
                return 'is-danger'      
            }
            if (row.volAvailablePercentage < row.warningVolumePercentage) {
                return 'is-warning'      
            }
                    
            return 'is-success'
        }
    },

    mounted() {

        if (process.env.VUE_APP_API_ROOT_URL.endsWith('/')) {
            this.api_url = process.env.VUE_APP_API_ROOT_URL
        } else {
            this.api_url = process.env.VUE_APP_API_ROOT_URL + '/'
        }

        this.user = this.getUser()
        this.fetchTankInventory()
    }
}


</script>