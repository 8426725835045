<template>
    <div class="section">
    
        <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
            <div class="card popupDialog">
                <div class="card-content">
                        <iframe id="pdf" class="pdfiFrame">
                            Your browser doesn't support iframes
                        </iframe> 
                </div>
            </div>
        </b-modal>        
    
    </div>
    <!-- <div class="box is-danger" v-else><div class="hero title">Not authorized</div></div> -->
    </template>
    
    <script>
    
    import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
    // import { PageSizes } from 'pdf-lib'
    // import { degrees } from 'pdf-lib'
    // import { rgb } from 'pdf-lib'
    import moment from 'moment'
    import UserStore from "../../../store/UserStore"
    // import { text } from 'body-parser'
    
    export default {
        props: {
            isShowPDFDialog: Boolean,
            tankInventory: {},
        },
    
        data() {
            return {
                pdfDoc: {},
                helveticaFont: {},
                pageNumber: 0,
                page: {},
                isShowPDFDialogLocal: false,
            }
    
        },
    
        methods: {
    
            closePDFDialog() {
                this.$emit('close-pdf-dialog')
            },
    
            createPdf: async(gthis) => {
    
                
                gthis.pdfDoc = await PDFDocument.create()
                gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
                gthis.pdfDoc.setTitle('Payroll Report')
                gthis.pdfDoc.setSubject('support@veecli.com')
                gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
                gthis.pdfDoc.setProducer('VEECLi Backoffice Software')
    
                gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)
    
                await gthis.pageHeader(gthis)
                // var text = ""
                var row = 480
                row -= 10
                gthis.tankInventory.tankInventoryList.forEach(tank => {
                    if (row < 80) {
                        gthis.pageHeader(gthis)
                        row = 480
                    }
                    row -= 20
                    gthis.drawTextLeft(gthis, row, 30, 10, moment(tank.lastModifiedDate).format('MM/DD/YYYY hh:mm a'))
                    gthis.drawTextLeft(gthis, row, 140, 10, tank.tankName)
                    gthis.drawTextLeft(gthis, row, 280, 10, tank.product)
                    gthis.drawTextLeft(gthis, row, 360, 10, tank.tankAlarmStatus)
                    gthis.drawTextLeft(gthis, row, 430, 10, tank.tankFuelVolumeStatus)
                    gthis.drawTextRight(gthis, row, 520, 10, tank.waterHeight.toFixed(1))
                    gthis.drawTextRight(gthis, row, 570, 10, tank.temperature.toFixed(0)+"°")
                    gthis.drawTextRight(gthis, row, 630, 10, tank.totalVolumeOfTank.toFixed(0))
                    gthis.drawTextRight(gthis, row, 680, 10, tank.ullage.toFixed(1))
                    gthis.drawTextRight(gthis, row, 730, 10, tank.volume.toFixed(1))
                    gthis.drawTextRight(gthis, row, 760, 10, tank.volAvailablePercentage.toFixed(0)+"%")


                    })
    
                // Create PDF Document
                const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
                document.getElementById('pdf').src = pdfDataUri;
            },
    
            pageHeader: async(gthis, titleText)  => {
    
                if (!titleText) {
                    titleText = "CURRENT FUEL INVENTORY REPORT"
                }
                gthis.pageNumber++
    
                gthis.page = gthis.pdfDoc.addPage([PageSizes.Letter[1],PageSizes.Letter[0]]);
                
                var storeName = UserStore.getters.getActiveStore.storeName
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);
    
                gthis.page.drawText(storeName, { 
                    x: 400 - (textWidth/2), 
                    y: 550, 
                    size: 14, 
                    })
    
                var row = 525
                var reportHead = titleText
                textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 15);
    
                gthis.page.drawText(reportHead, { 
                    x: 400 - (textWidth/2), 
                    y: row, 
                    size: 15, 
                    })            
    
                row = 500

                await gthis.pageSubHead(gthis,row)

                // var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
                // textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);
    
                // gthis.page.drawText(dateRange, { 
                //     x: 300 - (textWidth/2), 
                //     y: row, 
                //     size: 11, 
                //     })
    
                await gthis.pageFooter(gthis)                         
                    
            },
    
            pageSubHead: async(gthis,row) => {
                row -= 25
                gthis.drawColHeadLeft(gthis, row, 30, 12, 'Last Update')
                gthis.drawColHeadLeft(gthis, row, 140, 12, 'Tank Name')
                gthis.drawColHeadLeft(gthis, row, 280, 12, 'Product')
                gthis.drawColHeadLeft(gthis, row, 360, 12, 'Tank')
                gthis.drawColHeadLeft(gthis, row, 430, 12, 'Fuel')
                gthis.drawColHeadRight(gthis, row, 520, 12, 'Water')
                gthis.drawColHeadRight(gthis, row, 570, 12, 'Temp.')
                gthis.drawColHeadRight(gthis, row, 630, 12, 'Capacity')
                gthis.drawColHeadRight(gthis, row, 680, 12, 'Ullage')
                gthis.drawColHeadRight(gthis, row, 730, 12, 'Volume')
                gthis.drawColHeadRight(gthis, row, 760, 12, '%')                
            },
    
            veecliLogo: async(gthis) => {
                const pngUrl = '/images/veecli-logo.png'
                const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())
    
                const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
                const pngDims = pngImage.scale(0.08)
                
                gthis.page.drawImage(pngImage, {
                    x: 380,
                    y: 18,
                    width: pngDims.width,
                    height: pngDims.height,
                    rotate: degrees(0),
                    opacity: 0.75,
                })
                gthis.page.drawText(" VEECLi.com", { 
                    x: 388, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })                    
            },
    
            pageFooter: async(gthis) => {
    
                let timeNow = Date()
                timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')
    
                gthis.page.drawText(timeNow, { 
                    x: 20, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })
    
                var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
                gthis.page.drawText('Page ' + gthis.pageNumber, { 
                    x: 769 - textWidth, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })
                await gthis.veecliLogo(gthis)
            },
    
            getNextRow: async(gthis,row, count) => {
      
                if (!count) count = 1
                if (row > 75) {
                    return row - (5 * count)
                } else {
                    await gthis.pageHeader(gthis)
    
                    // Set Line to start records
                    return 680
                }
                
            },
    
            drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize, 
                })
                return row
            },
    
            drawRowTextLeft: async (gthis, row, text) => {
                row = await gthis.getNextRow(gthis,row, 2)
                // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
                if (text === "InsideSales") { text = "Inside Sales"}
                if (text === "BeerWine") { text = "Beer / Wine"}
                text = gthis.toTitleCase(text)
    
                gthis.page.drawText(text, { 
                    x: 45, 
                    y: row, 
                    size: 9, 
                })
                return row
            },
    
            drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRight: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawColHeadLeft:async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row+3, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                gthis.page.drawLine({
                    start: { x: colStart, y: row },
                    end: { x: colStart+textWidth+40, y: row  },
                    thickness: 1,
                    opacity: 0.4,
                })
                
            },
    
            drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row+3, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                gthis.page.drawLine({
                    start: { x: colStart - textWidth - 20, y: row },
                    end: { x: colStart, y: row  },
                    thickness: 1,
                    opacity: 0.4,
                })
                
            },
    
            drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
                gthis.page.drawText(text+" gal.", { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
                gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },        
    
            drawLine: async (gthis, row) => {
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.page.drawLine({
                    start: { x: 45, y: row },
                    end: { x: 500, y: row },
                    thickness: 1,
                    opacity: 0.2,
                })
                return row
            },
    
            drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
                gthis.page.drawRectangle({
                    x: colStart-4,
                    y: row-4,
                    width: width,
                    height: height,
                    rotate: degrees(0),
                    borderWidth: 0,
                    borderColor: grayscale(0.5),
                    color: rgb(0, 0, 0),
                    opacity: opacity,
                    borderOpacity: 0.75,
                })
            },
    
            async loadData() {
                this.isShowPDFDialogLocal = true
                this.createPdf(this)
                this.pageNumber = 0
            },
        },
      
    }
    </script>
    
    
    <style scoped>
    
    .popupDialog {
        /* position:absolute; */
        width:100%;
        height: 100%; 
        max-height: 1000px;
        min-height: 900px;     
    }
    
    .pdfiFrame {
        position:absolute; 
        top:0; 
        left:0; 
        bottom:0; 
        /* right:0;  */
        width:100%; 
        height:100%; 
        /* border:none; */
        /* margin-top: 50px; */
        /* margin-bottom: 50px; */
        /* padding:0;  */
        overflow:hidden; 
        /* z-index:999999;    */
    }
    div{
        height: 100%;
    }
    </style>